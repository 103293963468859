import React, { useState, useRef, useEffect } from "react";
import { FaTimes, FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import deviceTypeAImage from "../../images/device_type_A.png"; // Image pour le player physique
import deviceTypeBImage from "../../images/device_type_B.png"; // Image pour le player virtuel

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const PlayerTypeModal = ({
  isOpen,
  onClose,
  clerkId,
  onPlayerCreated,
  availableSlots,
  usedSlots,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleValidation = async () => {
    if (selectedOption === "virtual") {
      try {
        const response = await axios.post(
          `${apiUrl}/devices/create-virtual-player`,
          {
            clerkId,
            name: "Virtual Player",
          }
        );
        if (onPlayerCreated) {
          onPlayerCreated(response.data.device);
        }
      } catch (error) {
        console.error("Erreur lors de la création du player virtuel:", error);
      }
    } else if (selectedOption === "physical") {
      onPlayerCreated(null);
    }
    onClose();
  };

  const handleManageSubscription = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/stripe/create-customer-portal-session`,
        {
          clerkUserId: clerkId,
        }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error(
        "Erreur lors de la redirection vers le Customer Portal:",
        error
      );
    }
  };

  const isSlotsFull = availableSlots <= usedSlots;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-lg relative w-96"
      >
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <FaTimes className="text-2xl" />
        </button>

        <div className="mb-4">
          <h2 className="text-xl font-semibold text-left">Ajouter un Player</h2>
          <p className="text-sm text-gray-600">
            Choisissez le type de player à ajouter.
          </p>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div
            className={`border rounded-lg p-4 cursor-pointer flex flex-col items-center justify-center relative ${
              selectedOption === "virtual"
                ? "border-purple-600"
                : "border-gray-300"
            } ${isSlotsFull ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={() => !isSlotsFull && setSelectedOption("virtual")}
          >
            <img
              src={deviceTypeBImage}
              alt="Player Virtuel"
              className="w-auto h-16 mb-2 object-contain"
            />
            <span className="text-sm">Player Virtuel</span>
            {selectedOption === "virtual" && !isSlotsFull && (
              <FaCheckCircle className="text-purple-600 absolute top-2 right-2" />
            )}
          </div>

          <div
            className={`border rounded-lg p-4 cursor-pointer flex flex-col items-center justify-center relative ${
              selectedOption === "physical"
                ? "border-purple-600"
                : "border-gray-300"
            } ${isSlotsFull ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={() => !isSlotsFull && setSelectedOption("physical")}
          >
            <img
              src={deviceTypeAImage}
              alt="Player Physique"
              className="w-auto h-16 mb-2 object-contain"
            />
            <span className="text-sm">Player Physique</span>
            {selectedOption === "physical" && !isSlotsFull && (
              <FaCheckCircle className="text-purple-600 absolute top-2 right-2" />
            )}
          </div>
        </div>

        {isSlotsFull && (
          <>
            <div className="mt-4 p-2 bg-red-600 text-white text-center rounded text-xs">
              Vous avez atteint le nombre maximum de players disponibles avec
              votre abonnement.
            </div>
            <div className="mt-1 text-center text-xs text-black">
              <button onClick={handleManageSubscription} className="underline">
                Cliquez ici pour modifier votre abonnement
              </button>
            </div>
          </>
        )}

        <div className="flex justify-end mt-4">
          <button
            className={`hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 rounded-lg transition duration-200 ease-in-out flex items-center ${
              selectedOption === null || isSlotsFull
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer"
            }`}
            onClick={handleValidation}
            disabled={selectedOption === null || isSlotsFull}
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlayerTypeModal;
