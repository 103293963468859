import React, { useState, useEffect } from "react";
import Header from "../../components/Main/Header";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import SubscriptionCard from "./SubscriptionCard";
import EnterpriseCard from "./EnterpriseCard";
import FAQPricing from "./FAQPricing"; // Assurez-vous que le chemin est correct

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const BuySubscription = () => {
  const { user } = useUser();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [plan, setPlan] = useState("annual"); // Sélectionner annuel par défaut
  const [additionalPlayersBasic, setAdditionalPlayersBasic] = useState(0);
  const [additionalPlayersAdvanced, setAdditionalPlayersAdvanced] = useState(0);

  useEffect(() => {
    if (user) {
      const checkSubscriptionStatus = async () => {
        try {
          const response = await axios.post(
            `${apiUrl}/sub/check-subscription`,
            {
              clerkUserId: user.id,
            }
          );
          setHasActiveSubscription(response.data.isActive);
        } catch (error) {
          console.error("Error checking subscription status:", error);
        }
      };

      checkSubscriptionStatus();
    }
  }, [user, apiUrl]);

  const handleSubscription = async (selectedPlan, additionalPlayers) => {
    if (!user) {
      console.error("User information not available");
      return;
    }

    const stripePriceId = {
      basic:
        plan === "monthly"
          ? process.env.REACT_APP_STRIPE_PRICE_ID_BASIC_MONTHLY
          : process.env.REACT_APP_STRIPE_PRICE_ID_BASIC_ANNUAL,
      advanced:
        plan === "monthly"
          ? process.env.REACT_APP_STRIPE_PRICE_ID_ADVANCED_MONTHLY
          : process.env.REACT_APP_STRIPE_PRICE_ID_ADVANCED_ANNUAL,
    }[selectedPlan];

    if (!stripePriceId) {
      console.error(
        "Invalid plan type or Stripe Price ID not set",
        stripePriceId
      );
      return;
    }

    try {
      const sessionResponse = await axios.post(
        `${apiUrl}/sub/create-subscription-session`,
        {
          clerkId: user.id,
          email: user.primaryEmailAddress.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          planType: plan,
          planCategory: selectedPlan,
          additionalPlayers: additionalPlayers,
        }
      );

      const sessionId = sessionResponse.data.sessionId;
      if (sessionId) {
        const stripe = window.Stripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Stripe session ID is missing or invalid");
      }
    } catch (error) {
      console.error("Error creating Stripe subscription session:", error);
    }
  };

  const handleToggle = () => {
    setPlan((prevPlan) => (prevPlan === "monthly" ? "annual" : "monthly"));
  };

  const incrementPlayers = (type) => {
    if (type === "basic" && additionalPlayersBasic < 6) {
      setAdditionalPlayersBasic(additionalPlayersBasic + 1);
      setAdditionalPlayersAdvanced(0); // Reset advanced players
    } else if (type === "advanced" && additionalPlayersAdvanced < 6) {
      setAdditionalPlayersAdvanced(additionalPlayersAdvanced + 1);
      setAdditionalPlayersBasic(0); // Reset basic players
    }
  };

  const decrementPlayers = (type) => {
    if (type === "basic" && additionalPlayersBasic > 0) {
      setAdditionalPlayersBasic(additionalPlayersBasic - 1);
    } else if (type === "advanced" && additionalPlayersAdvanced > 0) {
      setAdditionalPlayersAdvanced(additionalPlayersAdvanced - 1);
    }
  };

  const savingsText =
    plan === "annual"
      ? `Économisez ${
          60 + (additionalPlayersBasic || additionalPlayersAdvanced)
        }€ en choisissant l'abonnement annuel!`
      : "";

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow bg-white py-8">
        {!hasActiveSubscription ? (
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center">
              <h1 className="text-4xl font-bold text-gray-900 mb-6">
                Sélectionnez un abonnement
              </h1>
              <p className="text-md text-gray-700 mb-8">
                Choisissez l'abonnement qui convient le mieux à vos besoins et
                passez à un plan supérieur à tout moment.
              </p>

              <div className="mb-4">
                <div className="flex items-center justify-center">
                  <span
                    className={`text-lg font-semibold ${
                      plan === "monthly" ? "text-gray-900" : "text-gray-400"
                    }`}
                  >
                    Mensuel
                  </span>
                  <label
                    htmlFor="plan-toggle"
                    className="flex items-center cursor-pointer mx-4"
                  >
                    <div className="relative">
                      <input
                        id="plan-toggle"
                        type="checkbox"
                        className="sr-only"
                        checked={plan === "annual"}
                        onChange={handleToggle}
                      />
                      <div className="block w-14 h-8 rounded-full transition duration-300 ease-in-out bg-gray-200 relative">
                        <div
                          className={`absolute inset-0 w-14 h-8 rounded-full transition ${
                            plan === "annual"
                              ? "bg-gradient-to-r from-indigo-500 to-pink-500"
                              : ""
                          }`}
                        ></div>
                        <div
                          className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full shadow-md transform transition ${
                            plan === "annual" ? "translate-x-6" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  </label>
                  <span
                    className={`text-lg font-semibold ${
                      plan === "annual" ? "text-gray-900" : "text-gray-400"
                    }`}
                  >
                    Annuel
                  </span>
                </div>
              </div>

              {savingsText && (
                <div className="text-center mb-4">
                  <p className="text-white text-sm bg-green-500 rounded-md px-2 py-1 text-center inline-block">
                    {savingsText}
                  </p>
                </div>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                <SubscriptionCard
                  plan={plan}
                  type="basic"
                  onSubscribe={(type, additionalPlayers) =>
                    handleSubscription(type, additionalPlayers)
                  }
                  additionalPlayers={additionalPlayersBasic}
                  incrementPlayers={() => incrementPlayers("basic")}
                  decrementPlayers={() => decrementPlayers("basic")}
                />
                <SubscriptionCard
                  plan={plan}
                  type="advanced"
                  onSubscribe={(type, additionalPlayers) =>
                    handleSubscription(type, additionalPlayers)
                  }
                  additionalPlayers={additionalPlayersAdvanced}
                  incrementPlayers={() => incrementPlayers("advanced")}
                  decrementPlayers={() => decrementPlayers("advanced")}
                />
              </div>

              <div className="w-full">
                <EnterpriseCard />
              </div>

              {/* Ajout du composant FAQPricing */}
              <div className="w-full mt-8">
                <FAQPricing />
              </div>
            </div>
          </div>
        ) : (
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Abonnement Actif
              </h2>
              <p className="text-md text-gray-700 mb-8">
                Vous avez actuellement un abonnement actif.
              </p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default BuySubscription;
