import React from "react";
import Header from "../../components/Main/Header";

const PaymentCancel = () => {
  // Ici, vous pouvez ajouter la logique pour gérer les actions post-annulation si nécessaire

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="flex-grow bg-white">
        <div className="w-full h-full flex items-center justify-center">
          <div className="max-w-6xl w-full px-4 sm:px-6 lg:px-8 py-12">
            <div className="bg-white p-6 md:p-12 rounded-lg shadow-none flex flex-col lg:flex-row items-center justify-between">
              <div className="lg:ml-12 mt-8 lg:mt-0 text-center">
                <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
                  Paiement Annulé
                </h2>
                <p className="text-gray-700 mb-4">
                  Votre processus de paiement a été annulé.
                </p>
                <p className="text-gray-500 mb-4">
                  Si vous avez rencontré un problème lors du paiement, n'hésitez
                  pas à nous contacter pour assistance. Vous pouvez également
                  essayer de renouveler votre achat si vous le souhaitez.
                </p>
                {/* Ajoutez ici des informations supplémentaires ou des actions post-annulation si nécessaire */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PaymentCancel;
