import React, { useState } from "react";

const FAQPricing = () => {
  const [openIndex, setOpenIndex] = useState(-1); // Index of the open question, -1 means none are open

  const questions = [
    {
      question: "Quels sont les différents types d'abonnements disponibles ?",
      answer:
        "Nous proposons deux types d'abonnements : Basique et Avancé. Le plan Basique permet la gestion d'un seul player et ne permet pas la création de playlists programmables en fonction de l'heure et de la date. Le plan Avancé permet la gestion de 2 players et inclut la création de playlists programmables.",
    },
    {
      question: "Quels sont les avantages d'un abonnement annuel ?",
      answer:
        "L'abonnement annuel vous permet d'économiser 60€ HT par an par rapport à l'abonnement mensuel. De plus, vous bénéficiez des mêmes fonctionnalités à un tarif réduit.",
    },
    {
      question: "Comment puis-je modifier ou annuler mon abonnement ?",
      answer:
        "Vous pouvez modifier ou annuler votre abonnement à tout moment via votre tableau de bord utilisateur. En cas d'annulation, votre abonnement restera actif jusqu'à la fin de la période payée.",
    },
    {
      question: "Quels types de contenus puis-je diffuser ?",
      answer:
        "Vous pouvez diffuser une variété de contenus, y compris des images, des vidéos, des présentations et du contenu interactif. Displex prend en charge différents formats pour répondre à vos besoins.",
    },
    {
      question:
        "Comment se passe la facturation pour les options de gestion supplémentaires ?",
      answer:
        "Vous pouvez ajouter des players supplémentaires pour 5€ par mois facturé mensuellement, ou 4€ par mois facturé annuellement. Ces coûts s'ajoutent à votre abonnement principal.",
    },
    {
      question: "Les players physiques sont-ils inclus dans l'abonnement ?",
      answer:
        "Non, les players physiques doivent être achetés séparément au prix de 79€ HT chacun. Cependant, vous pouvez créer autant de players virtuels que vous le souhaitez.",
    },
    {
      question: "Comment fonctionne le système de paiement ?",
      answer:
        "Tous les paiements sont sécurisés et traités via la plateforme Stripe. Nous acceptons les cartes de crédit et autres méthodes de paiement prises en charge par Stripe.",
    },
    {
      question: "Comment contacter le support client ?",
      answer:
        "Pour contacter notre équipe d'assistance, veuillez utiliser le <strong><a href='https://tally.so/r/wLGod2' target='_blank' rel='noopener noreferrer'>formulaire de contact</a></strong>. Nous nous efforçons de répondre à toutes vos questions dans les plus brefs délais.",
    },
    {
      question: "Quelles sont les limites de players pour chaque plan ?",
      answer:
        "Le plan Basique permet la gestion de jusqu'à 6 players avec les options supplémentaires, tandis que le plan Avancé permet de gérer jusqu'à 7 players. Pour des besoins plus importants, contactez-nous pour une offre personnalisée.",
    },
  ];

  const toggleAnswer = (index) => {
    if (openIndex === index) {
      setOpenIndex(-1); // Toggle close the currently open question
    } else {
      setOpenIndex(index); // Toggle open the clicked question
    }
  };

  return (
    <section className="bg-white bg-base-200" id="faq">
      <div className="py-24 px-8 max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-12">
          <div className="flex flex-col text-left md:basis-1/2">
            <p className="inline-block font-semibold text-primary mb-4">FAQ</p>
            <p className="text-3xl md:text-4xl font-extrabold text-base-content">
              Questions Fréquemment Posées
            </p>
          </div>
          <ul className="basis-1/2">
            {questions.map((q, index) => (
              <li key={index}>
                <button
                  className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
                  aria-expanded={openIndex === index}
                  onClick={() => toggleAnswer(index)}
                >
                  <span className="flex-1 text-base-content">{q.question}</span>
                  <svg
                    className={`flex-shrink-0 w-4 h-4 ml-auto fill-current transform transition duration-200 ${
                      openIndex === index ? "" : "rotate-180" // Rotate when closed
                    }`}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                      className="origin-center"
                    />
                    <rect
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                      className={`origin-center rotate-90 ${
                        openIndex === index ? "hidden" : ""
                      }`}
                    />
                  </svg>
                </button>
                <div
                  className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
                  style={{
                    maxHeight: openIndex === index ? "226px" : "0px",
                    opacity: openIndex === index ? 1 : 0,
                  }}
                >
                  <div className="pb-5 leading-relaxed">
                    <div className="space-y-2 leading-relaxed">
                      <p dangerouslySetInnerHTML={{ __html: q.answer }} />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQPricing;
