import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import DeviceCard from "./DeviceCard";
import { useSwipeable } from "react-swipeable";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const PlaylistModal = ({
  onSave,
  onCancel,
  editingPlaylist,
  refreshPlaylists,
}) => {
  const modalRef = useRef(null);
  const { user } = useUser();
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [allDay, setAllDay] = useState(false);
  const [allDays, setAllDays] = useState(editingPlaylist?.isRecurring ?? false);
  const [isVisible, setIsVisible] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const formatDate = (date) => {
    const d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
  };

  const today = formatDate(new Date());

  const initialStartDate = editingPlaylist?.playDate?.start
    ? formatDate(new Date(editingPlaylist.playDate.start))
    : today;
  const initialEndDate = editingPlaylist?.playDate?.end
    ? formatDate(new Date(editingPlaylist.playDate.end))
    : today;
  const initialStartTime = editingPlaylist?.playTime?.start
    ? editingPlaylist.playTime.start
    : "00:00";
  const initialEndTime = editingPlaylist?.playTime?.end
    ? editingPlaylist.playTime.end
    : "24:00";

  const [playlistDetails, setPlaylistDetails] = useState({
    name: editingPlaylist?.name ?? "",
    startDate: initialStartDate,
    endDate: initialEndDate,
    startTime: editingPlaylist?.playTime?.start ?? "09:00",
    endTime: editingPlaylist?.playTime?.end ?? "18:00",
    isRecurring: editingPlaylist?.isRecurring ?? false,
  });

  const [savedTimes, setSavedTimes] = useState({
    startTime: initialStartTime,
    endTime: initialEndTime,
  });

  const [savedDates, setSavedDates] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  const handleDeviceSelection = (deviceId) => {
    setSelectedDevices((prevDevices) =>
      prevDevices.includes(deviceId)
        ? prevDevices.filter((id) => id !== deviceId)
        : [...prevDevices, deviceId]
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlaylistDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, [name]: value };

      const [startHour, startMinute] = updatedDetails.startTime
        .split(":")
        .map(Number);
      const [endHour, endMinute] = updatedDetails.endTime
        .split(":")
        .map(Number);

      const startTimeInMinutes = startHour * 60 + startMinute;
      const endTimeInMinutes = endHour * 60 + endMinute;

      if (name === "startTime" && startTimeInMinutes >= endTimeInMinutes) {
        const adjustedEndTimeInMinutes = startTimeInMinutes + 5;
        const adjustedEndHour = Math.floor(adjustedEndTimeInMinutes / 60) % 24;
        const adjustedEndMinute = adjustedEndTimeInMinutes % 60;

        updatedDetails.endTime = `${String(adjustedEndHour).padStart(
          2,
          "0"
        )}:${String(adjustedEndMinute).padStart(2, "0")}`;
      }

      if (name === "endTime" && endTimeInMinutes <= startTimeInMinutes) {
        const adjustedStartTimeInMinutes = endTimeInMinutes - 5;
        const adjustedStartHour =
          Math.floor(adjustedStartTimeInMinutes / 60) % 24;
        const adjustedStartMinute = adjustedStartTimeInMinutes % 60;

        updatedDetails.startTime = `${String(adjustedStartHour).padStart(
          2,
          "0"
        )}:${String(adjustedStartMinute).padStart(2, "0")}`;
      }

      return updatedDetails;
    });
  };

  useEffect(() => {
    const fetchDevices = async () => {
      if (user?.id) {
        try {
          const response = await axios.get(
            `${apiUrl}/devices/userDevices/${user.id}`
          );
          setDevices(response.data.devices);

          if (editingPlaylist) {
            const editingDeviceIds = editingPlaylist.deviceIds.map(
              (device) => device._id
            );
            setSelectedDevices(editingDeviceIds);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des appareils", error);
        }
      }
    };
    fetchDevices();
  }, [user?.id, editingPlaylist]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation du nom de la playlist
    if (!playlistDetails.name.trim()) {
      setErrorMessage("Le nom de la playlist est requis.");
      return;
    }

    // Validation de la date de fin antérieure à la date de début
    const startDate = new Date(playlistDetails.startDate);
    const endDate = new Date(playlistDetails.endDate);

    if (endDate < startDate) {
      setErrorMessage(
        "La date de fin ne peut pas être antérieure à la date de début."
      );
      return;
    }

    // Validation de l'heure de début et de fin
    if (playlistDetails.startTime === playlistDetails.endTime && !allDay) {
      setErrorMessage(
        "L'heure de début et l'heure de fin ne peuvent pas être identiques."
      );
      return;
    }

    setErrorMessage(""); // Réinitialise le message d'erreur si tout est valide

    const clerkUserId = user.id;

    try {
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${clerkUserId}`
      );
      const userId = userResponse.data.userId;

      const payload = {
        name: playlistDetails.name,
        userId,
        deviceIds: selectedDevices,
        playDate: {
          start: new Date(
            `${playlistDetails.startDate}T${playlistDetails.startTime}:00`
          ).toISOString(),
          end: new Date(
            `${playlistDetails.endDate}T${
              playlistDetails.endTime === "24:00"
                ? "24:00"
                : playlistDetails.endTime
            }:00`
          ).toISOString(),
        },
        playTime: {
          start: playlistDetails.startTime,
          end: playlistDetails.endTime,
        },
        isRecurring: allDays,
      };

      let response;
      if (editingPlaylist) {
        response = await axios.put(
          `${apiUrl}/playlist/edit/${editingPlaylist._id}`,
          payload
        );
      } else {
        response = await axios.post(`${apiUrl}/playlist/create`, payload);
      }

      onSave();
      refreshPlaylists();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de la playlist", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsVisible(false);
        setTimeout(() => onCancel(), 300);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCancel]);

  useEffect(() => {
    setIsRendered(true);
    setTimeout(() => setIsVisible(true), 10);
  }, []);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const y = e.touches[0].clientY;
      setCurrentY(y);
      const diff = y - startY;
      if (diff > 0) {
        modalRef.current.style.transform = `translateY(${diff}px)`;
      }
    }
  };

  const handleTouchEnd = () => {
    const diff = currentY - startY;
    setIsDragging(false);

    if (diff > 100) {
      setIsVisible(false);
      setTimeout(() => onCancel(), 300);
    } else {
      modalRef.current.style.transform = "translateY(0)";
    }
  };

  const toggleAllDay = () => {
    if (!allDay) {
      setSavedTimes({
        startTime: playlistDetails.startTime,
        endTime: playlistDetails.endTime,
      });
      setPlaylistDetails((prevDetails) => ({
        ...prevDetails,
        startTime: "00:00",
        endTime: "24:00",
      }));
    } else {
      setPlaylistDetails((prevDetails) => ({
        ...prevDetails,
        startTime: savedTimes.startTime,
        endTime: savedTimes.endTime,
      }));
    }
    setAllDay(!allDay);
  };

  const toggleAllDays = () => {
    if (!allDays) {
      setSavedDates({
        startDate: playlistDetails.startDate,
        endDate: playlistDetails.endDate,
      });
      setPlaylistDetails((prevDetails) => ({
        ...prevDetails,
        isRecurring: true,
        startDate: today,
        endDate: formatDate(new Date("2099-12-31")),
      }));
    } else {
      setPlaylistDetails((prevDetails) => ({
        ...prevDetails,
        isRecurring: false,
        startDate: savedDates.startDate,
        endDate: savedDates.endDate,
      }));
    }
    setAllDays(!allDays);
  };

  const generateStartTimeOptions = () => {
    const times = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = String(hour).padStart(2, "0");
        const formattedMinute = String(minute).padStart(2, "0");
        const formattedTime = `${formattedHour}:${formattedMinute}`;
        times.push(formattedTime);
      }
    }

    return times;
  };

  const generateEndTimeOptions = () => {
    const times = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        if (hour === 0 && minute === 0) continue;
        const formattedHour = String(hour).padStart(2, "0");
        const formattedMinute = String(minute).padStart(2, "0");
        const formattedTime = `${formattedHour}:${formattedMinute}`;
        times.push(formattedTime);
      }
    }

    times.push("24:00");

    return times;
  };

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      setIsVisible(false);
      setTimeout(() => onCancel(), 300);
    },
    delta: 10, // Sensibilité du swipe
  });

  return isRendered ? (
    <div
      {...swipeHandlers} // Ajoute les gestionnaires de swipe ici
      className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-end sm:items-center justify-center z-50 sm:flex-col"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        ref={modalRef}
        className={`bg-white p-6 rounded-t-lg sm:rounded-lg shadow-xl max-w-lg w-full transition-transform duration-300 ease-in-out ${
          isVisible
            ? "translate-y-0 sm:translate-y-0 animate-slideInUp sm:animate-none"
            : "translate-y-full sm:translate-y-0 animate-slideOutDown sm:animate-none"
        }`}
      >
        <h2 className="text-xl font-semibold mb-6">Création d'une playlist</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom de la playlist :
            </label>
            <input
              type="text"
              name="name"
              value={playlistDetails.name}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
            />
            {errorMessage && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}
          </div>
          {!allDays && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date de diffusion :
              </label>
              <div className="flex justify-between items-center">
                <input
                  type="date"
                  name="startDate"
                  value={playlistDetails.startDate}
                  onChange={handleInputChange}
                  className="rounded-md mt-1 block w-1/2 px-3 py-2 bg-white border border-gray-300 rounded-lg-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-2"
                />
                <input
                  type="date"
                  name="endDate"
                  value={playlistDetails.endDate}
                  onChange={handleInputChange}
                  className="rounded-md mt-1 block w-1/2 px-3 py-2 bg-white border border-gray-300 rounded-lg-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ml-2"
                />
              </div>
            </div>
          )}
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              id="allDays"
              checked={allDays}
              onChange={toggleAllDays}
              className="mr-2"
            />
            <label htmlFor="allDays" className="text-sm text-gray-700">
              Tous les jours
            </label>
          </div>
          {!allDay && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Heure de diffusion :{" "}
                <span className="text-xs text-gray-500">(chaque jour)</span>
              </label>
              <div className="flex justify-between items-center">
                <select
                  name="startTime"
                  value={playlistDetails.startTime}
                  onChange={handleInputChange}
                  className="rounded-md mt-1 block w-1/2 px-3 py-2 bg-white border border-gray-300 rounded-lg-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-2"
                >
                  {generateStartTimeOptions().map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                <select
                  name="endTime"
                  value={playlistDetails.endTime}
                  onChange={handleInputChange}
                  className="rounded-md mt-1 block w-1/2 px-3 py-2 bg-white border border-gray-300 rounded-lg-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ml-2"
                  disabled={!playlistDetails.startTime}
                >
                  {generateEndTimeOptions().map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              id="allDay"
              checked={allDay}
              onChange={toggleAllDay}
              className="mr-2"
            />
            <label htmlFor="allDay" className="text-sm text-gray-700">
              Toute la journée
            </label>
          </div>
          <div className="mt-4">
            <fieldset>
              <legend className="block text-sm font-medium text-gray-700 mb-2">
                Sélectionnez des appareils :
              </legend>
              <div className="space-y-2">
                {Array.isArray(devices) && devices.length > 0 ? (
                  devices.map((device) => (
                    <DeviceCard
                      key={device._id}
                      device={device}
                      isSelected={selectedDevices.includes(device._id)}
                      onSelect={handleDeviceSelection}
                    />
                  ))
                ) : (
                  <p>Aucun appareil trouvé.</p>
                )}
              </div>
            </fieldset>
          </div>
          <div className="flex justify-end">
            <button
              className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 rounded-lg transition duration-200 ease-in-out flex mr-2"
              type="submit"
            >
              Confirmer
            </button>
            <button
              type="button"
              onClick={() => {
                setIsVisible(false);
                setTimeout(() => onCancel(), 300);
              }}
              className="bg-gray-900 text-white py-2 px-4 rounded-lg hover:bg-gray-500"
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default PlaylistModal;
