import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "@clerk/clerk-react";
import Modal from "../Modals/Modal";
import VideoModal from "../Modals/VideoModal";
import ImageModal from "../Modals/ImageModal";
import MediaItem from "./MediaItem";
import FileUpload from "./FileUpload";
import { FaImages } from "react-icons/fa";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MyMedias = () => {
  const { user } = useUser();
  const [mediaFiles, setMediaFiles] = useState([]);
  const [thumbnailUrls, setThumbnailUrls] = useState({});
  const [actionMenuOpen, setActionMenuOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [currentMediaUrl, setCurrentMediaUrl] = useState(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const inputRef = useRef(null);

  const fetchMedias = useCallback(async () => {
    try {
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${user.id}`
      );
      const userId = userResponse.data.userId;

      const response = await axios.get(`${apiUrl}/media/list/${userId}`);
      setMediaFiles(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des fichiers multimédias :",
        error
      );
    }
  }, [user.id]);

  // Update thumbnail URLs
  useEffect(() => {
    const updateThumbnailUrls = async () => {
      const newThumbnailUrls = {};

      for (const media of mediaFiles) {
        const thumbnailUrl = `${apiUrl}/media/thumbnail/${media.userId}/${media.fileName}`;
        newThumbnailUrls[media.fileName] = thumbnailUrl;
      }

      setThumbnailUrls(newThumbnailUrls);
    };

    if (mediaFiles.length > 0) {
      updateThumbnailUrls();
    }
  }, [mediaFiles]);

  useEffect(() => {
    fetchMedias();
  }, [fetchMedias]);

  const confirmDelete = (fileName) => {
    setIsModalOpen(true);
    setFileToDelete(fileName);
    setActionMenuOpen(null);
  };

  const deleteFile = async () => {
    setIsModalOpen(false);
    if (!fileToDelete) return;

    try {
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${user.id}`
      );
      const userId = userResponse.data.userId;

      await axios.delete(
        `${apiUrl}/media/delete/${encodeURIComponent(fileToDelete)}`,
        {
          data: { userId },
        }
      );

      setMediaFiles((prev) =>
        prev.filter((file) => file.fileName !== fileToDelete)
      );
      toast.success("Fichier supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression du fichier :", error);
      toast.error("Erreur lors de la suppression du fichier");
    }
  };

  const openMediaModal = async (fileName, mediaType) => {
    try {
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${user.id}`
      );
      const userId = userResponse.data.userId;

      const response = await axios.get(
        `${apiUrl}/media/view/${userId}/${fileName}`
      );
      const mediaUrl = response.data.url;

      setCurrentMediaUrl(mediaUrl);
      if (mediaType.startsWith("video")) {
        setIsVideoModalOpen(true);
      } else if (mediaType.startsWith("image")) {
        setIsImageModalOpen(true);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'URL signée", error);
      toast.error("Erreur lors de la récupération de l'URL signée");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setThumbnailUrls((prev) => ({
          ...prev,
          [file.name]: event.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadFile = async () => {
    if (!selectedFile) {
      toast.warn("Veuillez sélectionner un fichier à télécharger.");
      return;
    }

    const formData = new FormData();
    formData.append("mediaFile", selectedFile);
    formData.append("fileType", selectedFile.type);

    try {
      setUploadStatus("uploading");

      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${user.id}`
      );
      const userId = userResponse.data.userId;

      await axios.post(`${apiUrl}/media/upload/${userId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentage);
        },
      });

      setUploadStatus("completed");
      toast.success("Fichier téléchargé avec succès");
      setSelectedFile(null);
      setUploadProgress(0);
      inputRef.current.value = "";
      fetchMedias();
    } catch (error) {
      setUploadStatus("error");
      toast.error("Erreur lors du téléchargement du fichier");
    }
  };

  const toggleActionMenu = (index) => {
    setActionMenuOpen((prev) => (prev === index ? null : index));
  };

  // Fonction pour renommer le fichier média
  const renameMedia = async (oldFileName, newFileName) => {
    try {
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${user.id}`
      );
      const userId = userResponse.data.userId;

      await axios.post(`${apiUrl}/media/rename`, {
        userId,
        oldFileName,
        newFileName,
      });

      // Mettre à jour la liste des médias après le renommage
      setMediaFiles((prevMediaFiles) =>
        prevMediaFiles.map((file) =>
          file.fileName === oldFileName
            ? { ...file, fileName: newFileName }
            : file
        )
      );

      toast.success("Fichier renommé avec succès");
    } catch (error) {
      console.error("Erreur lors du renommage du fichier :", error);
      toast.error("Erreur lors du renommage du fichier");
    }
  };

  return (
    <div className="container mx-auto py-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center mb-4">
          <FaImages className="text-2xl text-gray-800 mr-2 mt-1" />
          <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
            Mes Médias
          </h2>
        </div>
      </div>
      {mediaFiles.length > 0 ? (
        <div className="flex-grow">
          {mediaFiles.map((media, index) => (
            <MediaItem
              key={index}
              media={media}
              index={index}
              thumbnailUrl={thumbnailUrls[media.fileName]}
              openMediaModal={openMediaModal}
              confirmDelete={confirmDelete}
              actionMenuOpen={actionMenuOpen}
              toggleActionMenu={toggleActionMenu}
              renameMedia={renameMedia} // Passer la fonction de renommage à MediaItem
            />
          ))}
        </div>
      ) : (
        <p className="text-center">Aucun fichier média trouvé</p>
      )}
      <FileUpload
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
        uploadFile={uploadFile}
        uploadStatus={uploadStatus}
        inputRef={inputRef}
      />
      {uploadProgress > 0 && (
        <div className="progress-bar w-full bg-gray-200 rounded-lg-full overflow-hidden mt-2">
          <div
            className="progress"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      )}
      {isModalOpen && (
        <Modal
          title="Confirmer la suppression"
          content={`Êtes-vous sûr de vouloir supprimer le fichier "${fileToDelete}" ?`}
          onConfirm={deleteFile}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
      {isVideoModalOpen && (
        <VideoModal
          videoUrl={currentMediaUrl}
          onClose={() => setIsVideoModalOpen(false)}
        />
      )}
      {isImageModalOpen && (
        <ImageModal
          imageUrl={currentMediaUrl}
          onClose={() => setIsImageModalOpen(false)}
        />
      )}
    </div>
  );
};

export default MyMedias;
