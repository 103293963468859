import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { FaRedo, FaNetworkWired, FaTimes, FaTrash } from "react-icons/fa";
import "../../displex-style.css";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const SettingsModal = ({ device, onSave, onDelete, onClose }) => {
  const [isEditing, setIsEditing] = useState(true);
  const [editedName, setEditedName] = useState(device.name);
  const [newName, setNewName] = useState(device.name);
  const [toReboot, setToReboot] = useState(device.toReboot);
  const [isOnline, setIsOnline] = useState(true); // New state for tracking device online status

  useEffect(() => {
    setEditedName(device.name);
    setNewName(device.name);
    setToReboot(device.toReboot);

    // Check device online status based on lastOnline timestamp
    const now = new Date();
    const lastOnline = new Date(device.lastOnline);
    const diffInSeconds = (now - lastOnline) / 1000;
    setIsOnline(diffInSeconds <= 60); // Update isOnline state
  }, [device]);

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const resetInputs = () => {
    setEditedName(device.name);
    setNewName(device.name);
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    const updatedData = {
      name: newName,
    };

    console.log("Saving updated device info:", updatedData);

    axios
      .put(`${apiUrl}/devices/update-device-info/${device._id}`, updatedData)
      .then((response) => {
        console.log("Device info updated successfully", response.data);
        onSave(response.data);
        setIsEditing(false);
      })
      .catch((error) => {
        console.error("Error updating device info", error);
      });
  };

  const handleDeleteClick = () => {
    console.log("Delete button clicked. Deleting virtual device.");

    axios
      .delete(`${apiUrl}/devices/delete-virtual-player/${device._id}`)
      .then((response) => {
        console.log("Virtual device deleted successfully", response.data);
        onDelete(device._id);
        onClose(); // Close the modal after deletion
      })
      .catch((error) => {
        console.error("Error deleting virtual device", error);
      });
  };

  const handleRestartClick = () => {
    const updatedData = {
      toReboot: true,
    };

    console.log(
      "Restart button clicked. Setting device to reboot with data:",
      updatedData
    );

    axios
      .put(`${apiUrl}/devices/reboot-device/${device._id}`, updatedData)
      .then((response) => {
        console.log("Device set to reboot successfully", response.data);
        setToReboot(true); // Update the state to reflect the change
        onClose(); // Close the modal
      })
      .catch((error) => {
        console.error("Error setting device to reboot", error);
      });
  };

  const modalRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  if (!device) return null;

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      ></div>
      <div
        ref={modalRef}
        className="relative bg-white p-4 sm:p-6 rounded-lg shadow-lg border border-gray-100 z-50"
        style={{ maxWidth: "80%", maxHeight: "70vh" }}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-lg leading-none text-gray-600 hover:text-gray-900"
          style={{ opacity: 0.7, transform: "scale(0.9)" }}
        >
          <FaTimes />
        </button>
        <div className="modal-content overflow-auto">
          <div className="border-b pb-3 mb-4 text-center">
            <h1 className="text-lg font-semibold mb-1">
              Paramètres du lecteur
            </h1>
            <p className="text-gray-600 text-xs">{device._id}</p>
          </div>
          <div className="grid grid-cols-1 gap-2 mb-4">
            <SettingField
              label="Nom du Device"
              icon={<FaNetworkWired className="text-indigo-500" />}
              value={newName}
              onChange={handleNameChange}
              isEditing={isEditing}
              id="name"
              maxLength={20}
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 mr-2 rounded-lg transition duration-200 ease-in-out"
              onClick={handleSaveClick}
            >
              Confirmer
            </button>
            {device.isVirtual ? (
              <button
                className="bg-red-600 text-white py-2 px-4 rounded-lg flex items-center hover:bg-red-800"
                onClick={handleDeleteClick}
              >
                <FaTrash className="mr-2" />
                Supprimer
              </button>
            ) : (
              <button
                className={`bg-gray-900 text-white py-2 px-4 rounded-lg flex items-center ${
                  !isOnline || toReboot
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-500"
                }`}
                onClick={handleRestartClick}
                disabled={!isOnline || toReboot}
              >
                <FaRedo className="mr-2" />
                Redémarrer
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingField = ({
  label,
  icon,
  value,
  onChange,
  isEditing,
  id,
  type = "text",
  maxLength,
}) => {
  return (
    <div className="flex flex-col items-start mb-2 space-y-1">
      <label htmlFor={id} className="font-semibold text-sm text-gray-700">
        {label}
      </label>
      <div className="flex items-center space-x-2">
        {icon}
        {isEditing ? (
          <input
            id={id}
            type={type}
            value={value}
            onChange={onChange}
            className="form-input w-full p-2 rounded-lg border border-gray-300"
            maxLength={maxLength}
          />
        ) : (
          <p className="w-full p-2 rounded-lg border border-gray-300">
            {value}
          </p>
        )}
      </div>
    </div>
  );
};

export default SettingsModal;
