import React, { useState, useEffect } from "react";
import Header from "../components/Main/Header";
import playerImage from "../images/player-2.png";
import playersImage from "../images/player-3.png";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";

// Define base URLs
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const FirstPlayer = () => {
  const { user } = useUser();
  const [usedSlots, setUsedSlots] = useState(0);
  const [availableSlots, setAvailableSlots] = useState(0);

  useEffect(() => {
    const fetchSlots = async () => {
      if (user?.id) {
        try {
          const response = await axios.post(
            `${apiUrl}/sub/check-subscription`,
            {
              clerkUserId: user.id,
            }
          );
          setUsedSlots(response.data.usedSlots);
          setAvailableSlots(response.data.availableSlots);
        } catch (error) {
          console.error("Error checking subscription status:", error);
        }
      }
    };

    fetchSlots();
  }, [user]);

  const handlePurchase = async (quantity) => {
    if (!user) {
      console.error("User information not available");
      return;
    }

    const primaryPhoneNumber = user.phoneNumbers.find(
      (p) => p.id === user.primaryPhoneNumberId
    )?.phoneNumber;

    const userInfo = {
      clerkId: user.id,
      email: user.primaryEmailAddress.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: primaryPhoneNumber,
    };

    try {
      await axios.post(`${apiUrl}/users/upsert`, userInfo);
      const stripePriceId = process.env.REACT_APP_STRIPE_PRICE_ID_PLAYER;
      const additionalPlayers = quantity - (availableSlots - usedSlots);
      const purchaseData = {
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        stripePriceId: stripePriceId,
        clerkUserId: userInfo.clerkId,
        phoneNumber: primaryPhoneNumber,
        quantity,
        additionalPlayers: additionalPlayers > 0 ? additionalPlayers : 0,
      };

      const sessionResponse = await axios.post(
        `${apiUrl}/stripe/create-checkout-session`,
        purchaseData
      );

      const session = sessionResponse.data;
      if (!session || !session.sessionId) {
        console.error("Stripe session ID is missing");
        return;
      }

      const stripe = window.Stripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );
      await stripe.redirectToCheckout({ sessionId: session.sessionId });
    } catch (error) {
      console.error("Error creating Stripe payment session:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow flex items-center justify-center">
        <div className="max-w-6xl w-full px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid gap-8 lg:grid-cols-2 items-start justify-center w-full h-full bg-white p-6 md:p-12 rounded-lg shadow-none">
            <div className="flex flex-col items-center text-center border p-4 rounded-lg h-full">
              <img
                src={playerImage}
                alt="Displex Player"
                className="w-full h-64 object-contain"
              />
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Displex Player
              </h2>
              <p className="text-gray-700 mb-4">
                Découvrez notre player compact : idéal pour diffuser vos visuels
                sur tout écran HDMI. Simple à gérer via une interface web, il
                révolutionne votre affichage numérique.
              </p>
              <p className="text-gray-500 mb-1">
                Inclus : Boitier de diffusion, Alimentation, Câble HDMI & RJ45.
              </p>
              <div className="text-2xl font-bold text-purple-600">€79.00</div>
              <p className="text-sm text-gray-500">
                En stock • Livraison gratuite
              </p>
              <div className="mt-6">
                <button
                  className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white px-6 py-3 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
                  onClick={() => handlePurchase(1)}
                >
                  ACHETER
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center text-center border p-4 rounded-lg h-full">
              <img
                src={playersImage}
                alt="Displex Players"
                className="w-full h-64 object-contain"
              />
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Pack Displex Players
              </h2>
              <p className="text-gray-700 mb-4">
                Optez pour notre pack de deux players compacts : parfaits pour
                diffuser vos visuels sur tout écran HDMI. Faciles à gérer via
                une interface web, ils transforment votre affichage numérique.
              </p>
              <p className="text-gray-500 mb-1">
                Inclus : 2 Boitiers de diffusion, Alimentation, Câble HDMI &
                RJ45.
              </p>
              <div className="text-2xl font-bold text-purple-600">€158.00</div>
              <p className="text-sm text-gray-500">
                En stock • Livraison gratuite
              </p>
              <div className="mt-6">
                <button
                  className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white px-6 py-3 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
                  onClick={() => handlePurchase(2)}
                >
                  ACHETER
                </button>
              </div>
            </div>
            <div className="lg:col-span-2 flex flex-col items-center text-center border p-4 rounded-lg">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Besoin de plus de players ?
              </h3>
              <p className="text-gray-700 mb-4">
                Si vous avez besoin de plus de players, veuillez nous contacter.
              </p>
              <button className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white px-6 py-3 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl">
                <a href="/contact">Contactez-nous</a>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FirstPlayer;
