import React from "react";
import { Link } from "react-router-dom";

const GenericModal = ({
  isOpen,
  onClose,
  title,
  content,
  linkPath,
  linkText,
  onLinkClick,
}) => {
  if (!isOpen) return null;

  const handleLinkClick = () => {
    if (onLinkClick) {
      onLinkClick();
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-3xl w-full mx-4 sm:mx-8 relative">
        {/*<button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>*/}
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">{title}</h2>
          <p className="text-gray-700 mb-6">{content}</p>
          <Link
            to={linkPath}
            className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white px-6 py-3 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
            onClick={handleLinkClick}
            style={{ display: "inline-block" }}
          >
            {linkText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
