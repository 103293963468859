import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { FaDownload, FaFileInvoice } from "react-icons/fa";

// Define base URLs
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MyInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    const fetchUserInvoices = async () => {
      setLoading(true);
      const userId = user?.id; // Assurez-vous que userId est défini ici
      try {
        const response = await fetch(`${apiUrl}/stripe/invoices/${userId}`);
        if (response.ok) {
          const data = await response.json();
          setInvoices(Array.isArray(data.data) ? data.data : []);
        } else if (response.status === 404) {
          setInvoices([]);
        } else {
          throw new Error(`Server responded with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) fetchUserInvoices(); // Utilisez user?.id ici pour s'assurer que fetchUserInvoices est appelé seulement si userId est disponible
  }, [user?.id, apiUrl]);

  function cleanProductName(description) {
    const match = description.match(/× (.+) \(at/);
    return match && match[1] ? match[1].trim() : "Inconnu";
  }

  return (
    <div className="container mx-auto py-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center mb-4">
          <FaFileInvoice className="text-2xl text-gray-800 mr-2 mt-1" />
          <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
            Mes Factures
          </h2>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md">
        <>
          {loading ? (
            <div className="text-center">Chargement en cours...</div>
          ) : (
            <>
              {invoices.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Produit
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Référence
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Montant
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Télécharger
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.map((invoice) => (
                        <tr key={invoice.id}>
                          {/* Description from line items */}
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {invoice.lines.data &&
                              invoice.lines.data.length > 0
                                ? cleanProductName(
                                    invoice.lines.data[0].description
                                  )
                                : "Inconnu"}
                            </p>
                          </td>
                          {/* Reference */}
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {invoice.number}
                            </p>
                          </td>
                          {/* Date */}
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(
                                invoice.created * 1000
                              ).toLocaleDateString()}
                            </p>
                          </td>
                          {/* Amount Due */}
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {(invoice.total_excluding_tax / 100).toFixed(2)}€
                            </p>
                          </td>
                          {/* Download Link */}
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                            <a
                              href={invoice.invoice_pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 inline-flex items-center justify-center"
                            >
                              <FaDownload className="h-4 w-4 mr-2" />
                              Télécharger
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>Aucune facture disponible.</div>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default MyInvoices;
