import React, { useState, useEffect } from "react";
import Header from "../../components/Main/Header";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import player1Image from "../../images/player-2.png"; // Update with correct image paths
import player2Image from "../../images/player-3.png";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const WelcomeOffer = () => {
  const { user } = useUser();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [numPlayers, setNumPlayers] = useState(1);

  useEffect(() => {
    if (user) {
      const checkSubscriptionStatus = async () => {
        try {
          const response = await axios.post(
            `${apiUrl}/sub/check-subscription`,
            {
              clerkUserId: user.id,
            }
          );
          setHasActiveSubscription(response.data.isActive);
        } catch (error) {
          console.error("Error checking subscription status:", error);
        }
      };

      checkSubscriptionStatus();
    }
  }, [user, apiUrl]);

  const handleSubscription = async (selectedPlan) => {
    if (!user) {
      console.error("User information not available");
      return;
    }

    const stripePriceId = {
      basic:
        numPlayers === 1
          ? process.env.REACT_APP_STRIPE_PRICE_ID_BASIC_MONTHLY
          : process.env.REACT_APP_STRIPE_PRICE_ID_BASIC_ANNUAL,
      advanced:
        numPlayers === 1
          ? process.env.REACT_APP_STRIPE_PRICE_ID_ADVANCED_MONTHLY
          : process.env.REACT_APP_STRIPE_PRICE_ID_ADVANCED_ANNUAL,
    }[selectedPlan];

    if (!stripePriceId) {
      console.error(
        "Invalid plan type or Stripe Price ID not set",
        stripePriceId
      );
      return;
    }

    try {
      const sessionResponse = await axios.post(
        `${apiUrl}/sub/create-subscription-session`,
        {
          clerkId: user.id,
          email: user.primaryEmailAddress.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          planType: numPlayers === 1 ? "monthly" : "annual",
          planCategory: selectedPlan,
        }
      );

      const sessionId = sessionResponse.data.sessionId;
      if (sessionId) {
        const stripe = window.Stripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Stripe session ID is missing or invalid");
      }
    } catch (error) {
      console.error("Error creating Stripe subscription session:", error);
    }
  };

  const handleToggle = () => {
    setNumPlayers((prevNumPlayers) => (prevNumPlayers === 1 ? 2 : 1));
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow bg-white py-8 flex items-center justify-center">
        {!hasActiveSubscription ? (
          <div className="container mx-auto px-4 flex flex-col items-center justify-center">
            <h1 className="text-4xl font-bold text-gray-900 mb-6">
              Bienvenue à nos offres spéciales
            </h1>
            <p className="text-md text-gray-700 mb-8">
              Choisissez l'offre qui convient le mieux à vos besoins.
            </p>

            <div className="mb-4">
              <div className="flex items-center justify-center">
                <span
                  className={`text-lg font-semibold ${
                    numPlayers === 1 ? "text-gray-900" : "text-gray-400"
                  }`}
                >
                  1 player
                </span>
                <label
                  htmlFor="player-toggle"
                  className="flex items-center cursor-pointer mx-4"
                >
                  <div className="relative">
                    <input
                      id="player-toggle"
                      type="checkbox"
                      className="sr-only"
                      checked={numPlayers === 2}
                      onChange={handleToggle}
                    />
                    <div className="block w-14 h-8 rounded-full transition duration-300 ease-in-out bg-gray-200 relative">
                      <div
                        className={`absolute inset-0 w-14 h-8 rounded-full transition ${
                          numPlayers === 2
                            ? "bg-gradient-to-r from-indigo-500 to-pink-500"
                            : ""
                        }`}
                      ></div>
                      <div
                        className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full shadow-md transform transition ${
                          numPlayers === 2 ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </label>
                <span
                  className={`text-lg font-semibold ${
                    numPlayers === 2 ? "text-gray-900" : "text-gray-400"
                  }`}
                >
                  2 players
                </span>
              </div>
            </div>

            <div className="flex items-center justify-center mt-8">
              {numPlayers === 1 ? (
                <div className="bg-white shadow-xl rounded-lg p-4 flex flex-col items-center">
                  <img
                    src={player1Image}
                    alt="Displex Player"
                    className="w-48 h-auto rounded-md mb-4"
                  />
                  <h2 className="text-2xl font-bold mb-2 text-center">
                    Offre Spéciale: Player + Abonnement Displex Avancé
                  </h2>
                  <p className="mb-3 text-center">
                    Obtenez 1 player et un abonnement de 6 mois à Displex Avancé
                    pour seulement 249€ au lieu de 313€.
                  </p>
                  <p className="font-semibold text-center">(Économisez 21%)</p>
                  <button className="mt-3 hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white font-bold py-2 px-4 rounded-lg">
                    Profiter de l'offre
                  </button>
                </div>
              ) : (
                <div className="bg-white shadow-xl rounded-lg p-4 flex flex-col items-center">
                  <img
                    src={player2Image}
                    alt="Displex Player"
                    className="w-48 h-auto rounded-md mb-4"
                  />
                  <h2 className="text-2xl font-bold mb-2 text-center">
                    Offre Spéciale: 2 Players + Abonnement Displex Avancé
                  </h2>
                  <p className="mb-3 text-center">
                    Achetez 2 players et un abonnement de 6 mois à Displex
                    Advanced pour seulement 299€ au lieu de 392€.
                  </p>
                  <p className="font-semibold text-center">
                    (Économisez 23.72%)
                  </p>
                  <button className="mt-3 hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white font-bold py-2 px-4 rounded-lg">
                    Profiter de l'offre
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="container mx-auto px-4 flex flex-col items-center justify-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Abonnement Actif
            </h2>
            <p className="text-md text-gray-700 mb-8">
              Vous avez actuellement un abonnement actif.
            </p>
          </div>
        )}
      </main>
    </div>
  );
};

export default WelcomeOffer;
