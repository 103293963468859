import React, { useState } from "react";
import playerDiagram from "../images/hdmi.png";
import player2Image from "../images/player-2.png";
import player3Image from "../images/player-3.png";
import Header from "../components/Main/Header";

const ChoosePlan = () => {
  const [subscription, setSubscription] = useState("basic");
  const [boxCount, setBoxCount] = useState(1);

  const updateBoxCount = (change) => {
    setBoxCount((prev) => Math.max(0, prev + change)); // Ensure box count never goes below 0
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow bg-gray-100 p-4">
        {/* Existing Player introduction section */}
        <section className="bg-white shadow-xl rounded-lg m-2 md:m-6 p-4 md:p-6 flex flex-wrap md:flex-nowrap overflow-hidden">
          <div className="flex-initial w-full md:w-1/2 md:max-w-lg mx-auto">
            <img
              src={playerDiagram}
              alt="Player connected to screen"
              className="w-full h-auto transform transition duration-500 ease-in-out hover:scale-110"
            />
          </div>
          <div className="flex-1 text-left md:pl-6">
            <h1 className="text-3xl font-bold mb-4">
              Découvrez la puissance de notre Player
            </h1>
            <p className="text-lg text-gray-600">
              Notre player offre une diffusion de contenu numérique directement
              sur votre écran, sans complications. Connectez simplement le
              player à votre affichage via HDMI et profitez d'une lecture de
              média de haute qualité.
            </p>
          </div>
        </section>
        {/* Subscription Configurator Section */}
        <section className="bg-white shadow-xl rounded-lg m-2 md:m-6 p-4">
          <h2 className="text-2xl font-bold text-center mb-4">
            Configurez votre abonnement et votre player
          </h2>
          <div className="md:flex justify-around">
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">
                Choisir un abonnement :
              </h3>
              <div className="mb-2">
                <label>
                  <input
                    type="radio"
                    name="subscription"
                    value="basic"
                    checked={subscription === "basic"}
                    onChange={() => setSubscription("basic")}
                  />
                  Basique à 29€/mois ou 24€/mois annuellement
                </label>
                <p>Description : gestion de 1 player.</p>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="subscription"
                    value="advanced"
                    checked={subscription === "advanced"}
                    onChange={() => setSubscription("advanced")}
                  />
                  Avancé à 39€/mois ou 34€/mois annuellement
                </label>
                <p>Description : gestion de 2 players.</p>
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">
                Commander un boîtier :
              </h3>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => updateBoxCount(-1)}
                  className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                >
                  -
                </button>
                <span className="text-xl">{boxCount}</span>
                <button
                  onClick={() => updateBoxCount(1)}
                  className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                >
                  +
                </button>
                <span className="text-lg ml-2">à 79€ chacun</span>
              </div>
            </div>
          </div>
        </section>
        {/* Existing special offers sections */}
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 p-4">
          <div className="bg-white shadow-lg rounded-lg p-4 flex flex-col md:flex-row items-center">
            <img
              src={player2Image}
              alt="Displex Player"
              className="w-48 h-auto rounded-md mr-4"
            />
            <div>
              <h2 className="text-2xl font-bold mb-2">
                Offre Spéciale: Player + Abonnement Displex Avancé
              </h2>
              <p className="mb-3">
                Obtenez 1 player et un abonnement de 6 mois à Displex Avancé
                pour seulement 249€ au lieu de 313€.
              </p>
              <p className="font-semibold">(Économisez 21%)</p>
              <button className="mt-3 hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white font-bold py-2 px-4 rounded-lg">
                Profiter de l'offre
              </button>
            </div>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-4 flex flex-col md:flex-row items-center">
            <img
              src={player3Image}
              alt="Displex Player"
              className="w-48 h-auto rounded-md mr-4"
            />
            <div>
              <h2 className="text-2xl font-bold mb-2">
                Offre Spéciale: 2 Players + Abonnement Displex Avancé
              </h2>
              <p className="mb-3">
                Achetez 2 players et un abonnement de 6 mois à Displex Advanced
                pour seulement 299€ au lieu de 392€.
              </p>
              <p className="font-semibold">(Économisez 23.72%)</p>
              <button className="mt-3 hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white font-bold py-2 px-4 rounded-lg">
                Profiter de l'offre
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
