import React, { useRef, useEffect, useCallback } from "react";

const Modal = ({ title, content, onConfirm, onCancel }) => {
  const modalRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        console.log("Clicked outside the modal, closing it");
        onCancel();
      }
    },
    [onCancel]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        console.log("Enter key pressed, confirming");
        onConfirm();
      }
    },
    [onConfirm]
  );

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleOutsideClick, handleKeyPress]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-lg relative z-10"
      >
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <p className="mb-4">{content}</p>
        <div className="flex justify-end space-x-3">
          <button
            className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 rounded-lg transition duration-200 ease-in-out ml-2"
            onClick={() => {
              console.log("Confirm button clicked");
              onConfirm();
            }}
          >
            Confirmer
          </button>
          <button
            className="bg-gray-900 hover:bg-gray-500 text-white py-2 px-4 rounded-lg"
            onClick={() => {
              console.log("Cancel button clicked");
              onCancel();
            }}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
