import React, { useState } from "react";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { UserButton } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import logo from "../../images/logo.png";
import LogoDisplex from "./LogoDisplex";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="bg-white p-2 border-b border-gray-300">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="Logo" className="w-12 ml-4 mt-2 mb-2" />
            <LogoDisplex />
          </Link>
        </div>
        <div className="flex items-center"></div>
        <div className="flex justify-end items-center w-full mr-10">
          <SignedIn className>
            <UserButton afterSignOutUrl="/" />
          </SignedIn>
          <SignedOut>
            <div className="md:hidden">
              <div className="relative">
                <button
                  onClick={toggleMobileMenu}
                  className="text-white px-2 py-2 hover:text-gray-300 transition duration-300"
                >
                  <FaBars size={24} />
                </button>
                {isMobileMenuOpen && (
                  <div className="absolute bg-white text-black right-0 top-12 py-2 w-48 rounded-lg-md shadow-md z-50">
                    {" "}
                    {/* Augmentation du z-index à 50 */}
                    <Link
                      to="/sign-in"
                      className="block px-4 py-2 hover:bg-gray-100 transition duration-300"
                    >
                      Se connecter
                    </Link>
                    <Link
                      to="/sign-up"
                      className="block px-4 py-2 hover:bg-gray-100 transition duration-300"
                    >
                      S'enregistrer
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className={`hidden md:flex space-x-2`}>
              <Link
                to="/sign-in"
                className="text-white px-2 py-2 hover:text-gray-300 transition duration-300"
              >
                Se connecter
              </Link>
              <Link
                to="/sign-up"
                className="bg-white text-black px-4 py-2 rounded-lg hover:bg-gray-100 transition duration-300"
              >
                S'enregistrer
              </Link>
            </div>
          </SignedOut>
        </div>
      </div>
    </div>
  );
};

export default Header;
