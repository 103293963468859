import React, { useState, useEffect, useRef } from "react";
import productImage from "../../images/player-2.png";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const BuyPlayerModal = ({ isOpen, onClose }) => {
  const { user } = useUser();
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [availableSlots, setAvailableSlots] = useState(0);
  const [usedSlots, setUsedSlots] = useState(0); // Ajouter l'état pour usedSlots
  const modalRef = useRef(null);

  console.log("Rendering BuyPlayerModal component");

  useEffect(() => {
    const fetchSubscriptionAndDeviceInfo = async () => {
      if (user?.id) {
        try {
          // Récupérer les informations d'abonnement
          const subscriptionResponse = await axios.post(
            `${apiUrl}/sub/check-subscription`,
            {
              clerkUserId: user.id,
            }
          );
          setAvailableSlots(subscriptionResponse.data.availableSlots || 0);

          // Récupérer les informations des appareils
          const devicesResponse = await axios.get(
            `${apiUrl}/devices/userDevices/${user.id}`
          );
          setUsedSlots(devicesResponse.data.usedSlots || 0);

          console.log("Subscription and device info fetched successfully:", {
            availableSlots: subscriptionResponse.data.availableSlots,
            usedSlots: devicesResponse.data.usedSlots,
          });
        } catch (error) {
          console.error("Error fetching subscription or device info:", error);
        }
      }
    };

    fetchSubscriptionAndDeviceInfo();
  }, [user]);

  useEffect(() => {
    const isNumber = (value) => typeof value === "number" && !isNaN(value);

    const calculateSlotsRemaining = () => {
      if (!isNumber(availableSlots) || !isNumber(usedSlots)) {
        console.error("availableSlots or usedSlots is not a number", {
          availableSlots,
          usedSlots,
        });
        return 0; // Default value in case of invalid input
      }

      return availableSlots - usedSlots;
    };

    const slotsRemaining = calculateSlotsRemaining();

    if (quantity > slotsRemaining) {
      const excessUnits = quantity - slotsRemaining;
      const error = `Attention, vous allez dépasser votre limite de ${excessUnits} player${
        excessUnits > 1 ? "s" : ""
      }. Ajustez votre abonnement dans l'onglet pour gérer ${excessUnits} boîtier${
        excessUnits > 1 ? "s supplémentaires" : " supplémentaire"
      }.`;
      setErrorMessage(error);
      console.log(error);
    } else {
      setErrorMessage("");
      console.log("No error message, within slot limits.");
    }
  }, [quantity, availableSlots, usedSlots]);

  const handlePurchase = async () => {
    if (!user) {
      console.error("User information not available");
      return;
    }

    const primaryPhoneNumber = user.phoneNumbers.find(
      (p) => p.id === user.primaryPhoneNumberId
    )?.phoneNumber;

    const userInfo = {
      clerkId: user.id,
      email: user.primaryEmailAddress.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: primaryPhoneNumber,
    };

    try {
      await axios.post(`${apiUrl}/users/upsert`, userInfo);

      const stripePriceId = process.env.REACT_APP_STRIPE_PRICE_ID_PLAYER;
      const additionalPlayers = quantity - (availableSlots - usedSlots);
      const purchaseData = {
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        stripePriceId: stripePriceId,
        clerkUserId: userInfo.clerkId,
        phoneNumber: primaryPhoneNumber,
        quantity,
        additionalPlayers: additionalPlayers > 0 ? additionalPlayers : 0,
      };

      const sessionResponse = await axios.post(
        `${apiUrl}/stripe/create-checkout-session`,
        purchaseData
      );

      const session = sessionResponse.data;
      if (!session || !session.sessionId) {
        console.error("Stripe session ID is missing");
        return;
      }

      const stripe = window.Stripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );
      await stripe.redirectToCheckout({ sessionId: session.sessionId });
    } catch (error) {
      console.error("Error creating Stripe payment session:", error);
    }
  };

  const incrementQuantity = () => {
    setQuantity((q) => q + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((q) => q - 1);
    }
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-xl max-w-3xl w-full mx-4 sm:mx-8 relative overflow-y-auto max-h-[90vh] my-8"
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>
        <div className="flex flex-col items-center lg:flex-row">
          <img
            src={productImage}
            alt="Displex Player"
            className="lg:w-1/3 w-full lg:h-auto h-64 object-contain"
          />
          <div className="lg:ml-12 mt-8 lg:mt-0 text-center lg:text-left">
            <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
              Displex Player
            </h2>
            <p className="text-gray-700 mb-4">
              Offrez-vous notre player compact : idéal pour diffuser vos visuels
              sur tout écran HDMI, il se gère facilement via une interface web.
              Simple, efficace, il révolutionne votre affichage numérique.
            </p>
            <p className="text-gray-500 mb-1">
              Inclus : Boitier de diffusion, Alimentation, Câble HDMI & RJ45.
            </p>
            <div className="text-2xl lg:text-3xl font-bold text-purple-600">
              €{79 * quantity}.00
            </div>
            <p className="text-sm text-gray-500">
              en stock • livraison gratuite
            </p>
            <div className="mt-4 flex items-center justify-center lg:justify-start">
              <MinusCircleIcon
                className="w-8 h-8 text-purple-600 cursor-pointer"
                onClick={decrementQuantity}
              />
              <span className="mx-4 text-xl">{quantity}</span>
              <PlusCircleIcon
                className="w-8 h-8 text-purple-600 cursor-pointer"
                onClick={incrementQuantity}
              />
            </div>
            {errorMessage && (
              <p className="mt-4 text-red-600 font-semibold">{errorMessage}</p>
            )}
            <div className="mt-6">
              <button
                className="hover-bg-white max-h-11 upload-button text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500"
                onClick={handlePurchase}
              >
                ACHETER
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyPlayerModal;
