import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { FaImages } from "react-icons/fa";
import CalendarModal from "../Modals/CalendarModal";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MyCalendar = () => {
  const { user } = useUser();
  const [currentEvent, setCurrentEvent] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (user?.id) {
      axios
        .get(`${apiUrl}/devices/userDevices/${user.id}`)
        .then((response) => {
          if (Array.isArray(response.data.devices)) {
            setDevices(response.data.devices);
            if (response.data.devices.length > 0) {
              setSelectedDevice(response.data.devices[0]._id);
            }
          } else {
            console.error(
              "API response is not an array:",
              response.data.devices
            );
            toast.error("Erreur lors du chargement des appareils.");
          }
        })
        .catch((error) => console.error("Error fetching devices:", error));
    }
  }, [user]);

  useEffect(() => {
    if (selectedDevice) {
      fetchPlaylists(selectedDevice);
    }
  }, [selectedDevice]);

  const adjustTimezone = (date, hours) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(adjustedDate.getHours() + hours);
    return adjustedDate;
  };

  const fetchPlaylists = (deviceId) => {
    axios
      .get(`${apiUrl}/playlist/device/${deviceId}`)
      .then((response) => {
        const playlists = response.data;
        console.log("Playlists fetched for device:", playlists);

        const calendarEvents = playlists.flatMap((playlist) => {
          const startDate = new Date(playlist.playDate?.start);
          const endDate = new Date(playlist.playDate?.end);
          const startTime = playlist.playTime?.start;
          const endTime = playlist.playTime?.end;

          console.log(`Processing playlist ${playlist.name}`);
          console.log(`Start date: ${startDate}, End date: ${endDate}`);
          console.log(`Start time: ${startTime}, End time: ${endTime}`);

          if (!startDate || !endDate) {
            console.error(`Invalid dates for playlist ${playlist.name}`);
            toast.error(`Invalid dates for playlist ${playlist.name}`);
            return [];
          }

          const events = [];
          const currentDate = new Date(startDate);

          while (currentDate <= endDate) {
            const dateStr = adjustTimezone(new Date(currentDate), 0)
              .toISOString()
              .substring(0, 10);
            events.push({
              title: playlist.name,
              start: `${dateStr}T${startTime}:00`,
              end: `${dateStr}T${endTime}:00`,
              extendedProps: {
                playlistId: playlist._id,
                deviceIds: playlist.deviceIds,
                originalStartDate: playlist.playDate?.start,
                originalEndDate: playlist.playDate?.end,
              },
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }

          return events;
        });

        console.log("Transformed calendar events:", calendarEvents);
        setEvents(calendarEvents);
      })
      .catch((error) => {
        console.error("Error fetching playlists:", error);
        toast.error("Erreur lors du chargement des playlists.");
      });
  };

  const handleDateSelect = (selectInfo) => {
    const start = new Date(selectInfo.startStr);
    const end = new Date(selectInfo.endStr);
    setCurrentEvent({
      start: start.toISOString(),
      end: end.toISOString(),
      title: "",
      allDay: selectInfo.allDay,
    });
    setIsCreateModalOpen(true);
    setIsEdit(false);
  };

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    console.log("Event clicked:", event);
    console.log("Start:", event.start, "End:", event.end);

    // Vérifiez si event.start et event.end ne sont pas null avant d'utiliser toISOString()
    const startISO = event.start ? event.start.toISOString() : null;
    const endISO = event.end ? event.end.toISOString() : null;

    setCurrentEvent({
      id: event.id,
      title: event.title,
      start: startISO,
      end: endISO,
      extendedProps: event.extendedProps,
    });
    setIsCreateModalOpen(true);
    setIsEdit(true);
  };

  const saveEvent = (eventDetails, isEdit) => {
    console.log("Saving event:", eventDetails, "isEdit:", isEdit);

    if (!eventDetails) {
      console.log("Deleting event");
      fetchPlaylists(selectedDevice);
      return;
    }

    const newEvent = {
      title: eventDetails.name,
      start: adjustTimezone(
        new Date(`${eventDetails.startDate}T${eventDetails.startTime}:00`),
        -2
      ).toISOString(),
      end: adjustTimezone(
        new Date(`${eventDetails.endDate}T${eventDetails.endTime}:00`),
        -2
      ).toISOString(),
      extendedProps: {
        playlistId: eventDetails._id,
        deviceIds: eventDetails.deviceIds,
      },
    };

    console.log("New Event:", newEvent);

    if (isEdit) {
      console.log("Updating existing event");
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.extendedProps.playlistId === eventDetails._id ? newEvent : event
        )
      );
    } else {
      console.log("Adding new event");
      setEvents((prevEvents) => [...prevEvents, newEvent]);
    }

    // Fetch the updated playlists after saving
    fetchPlaylists(selectedDevice);

    console.log("Updated events:", events);
    setIsCreateModalOpen(false);
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <FaImages className="text-2xl text-gray-800 mr-2 mt-1" />
          <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
            Mon Calendrier
          </h2>
        </div>
        <select
          className="py-2 px-4 rounded-lg bg-white shadow"
          value={selectedDevice || ""}
          onChange={handleDeviceChange}
        >
          {devices.map((device) => (
            <option key={device._id} value={device._id}>
              {device.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-col h-full">
        <div className="border-2 border-gray-300 rounded-lg p-4 flex-grow bg-white shadow rounded-lg">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            selectable={true}
            select={handleDateSelect}
            events={events}
            eventClick={handleEventClick}
            locale={frLocale}
            height="auto"
          />
        </div>
      </div>

      {isCreateModalOpen && (
        <CalendarModal
          onSave={saveEvent}
          onCancel={() => setIsCreateModalOpen(false)}
          eventData={currentEvent}
          deviceId={selectedDevice}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};

export default MyCalendar;
