import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../components/Main/Header";
import GenericModal from "../components/Modals/GenericModal";
import Sidebar from "../components/Main/Sidebar";
import MyPlayers from "../components/Dashboard/MyPlayers";
import MyMedias from "../components/Dashboard/MyMedias";
import MyPlaylists from "../components/Dashboard/MyPlaylists";
import MyInvoices from "../components/Dashboard/MyInvoices";
import MySubscription from "../components/Dashboard/MySubscription";
import MyCalendar from "../components/Dashboard/MyCalendar";
import BuyPlayerModal from "../components/Modals/BuyPlayerModal";
import Changelog from "../pages/Changelog";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const Dashboard = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState("myPlayers");
  const [modalContent, setModalContent] = useState({});
  const [subscriptionType, setSubscriptionType] = useState("");
  const [isBuyPlayerModalOpen, setIsBuyPlayerModalOpen] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkSubscription = async () => {
    try {
      const subscriptionCheckUrl = `${apiUrl}/sub/check-subscription`;
      console.log("Subscription check URL:", subscriptionCheckUrl); // Log de l'URL

      const subscriptionResponse = await axios.post(subscriptionCheckUrl, {
        clerkUserId: user.id,
      });

      console.log("Subscription response:", subscriptionResponse.data); // Log de la réponse

      if (subscriptionResponse.data.isActive) {
        setSubscriptionType(subscriptionResponse.data.subscriptionType);
        setIsSubscriptionActive(true);
        console.log(
          "Subscription is active:",
          subscriptionResponse.data.subscriptionType
        );
      } else {
        setSubscriptionType(null);
        setIsSubscriptionActive(false);
        console.log("Subscription is inactive or not found");
        // Affichage de la modal si pas d'abonnement
        setModalContent({
          isOpen: true,
          title: "Abonnement requis",
          content:
            "Vous devez avoir un abonnement actif pour accéder à cette page.",
          linkPath: "/subscription",
          linkText: "Souscrire",
        });
      }

      console.log(
        "Subscription type in checkSubscription:",
        subscriptionResponse.data.subscriptionType
      );

      return subscriptionResponse.data.isActive;
    } catch (error) {
      console.error("Error checking subscription status:", error);
      setSubscriptionType(null);
      setModalContent({
        isOpen: true,
        title: "Erreur",
        content:
          "Un problème est survenu lors de la vérification de votre abonnement.",
        linkPath: "/support",
        linkText: "Support",
      });
      return false;
    }
  };

  const checkDeviceStatus = async () => {
    try {
      const checkDeviceUrl = `${apiUrl}/devices/check-device-status`;
      const deviceResponse = await axios.post(checkDeviceUrl, {
        clerkUserId: user.id,
      });

      console.log("Device status response:", deviceResponse.data); // Log de la réponse
      return deviceResponse.data.status;
    } catch (error) {
      console.error("Error checking device status:", error);
      return "error";
    }
  };

  useEffect(() => {
    if (user?.id) {
      const checkDeviceAndSubscription = async () => {
        console.log("Checking device and subscription for user:", user.id); // Log de l'utilisateur
        const deviceStatus = await checkDeviceStatus();

        if (deviceStatus === "error") {
          console.log("Error in device status, opening error modal");
          setModalContent({
            isOpen: true,
            title: "Erreur",
            content:
              "Un problème est survenu lors de la vérification de vos appareils.",
            linkPath: "/support",
            linkText: "Support",
          });
        } else {
          const isSubscriptionActive = await checkSubscription();
          if (isSubscriptionActive) {
            console.log("Subscription active, no modal needed");
            setModalContent({});
          } else {
            console.log("Subscription inactive, modal shown");
          }
        }
        setIsLoading(false);
      };

      checkDeviceAndSubscription();
    }
  }, [user, navigate]);

  const renderComponent = () => {
    switch (activeComponent) {
      case "myPlayers":
        return <MyPlayers isSubscriptionActive={isSubscriptionActive} />;
      case "myMedias":
        return <MyMedias />;
      case "myPlaylists":
        return <MyPlaylists />;
      case "myCalendar":
        return <MyCalendar />;
      case "myInvoices":
        return <MyInvoices />;
      case "mySubscription":
        return <MySubscription />;
      case "changelog":
        return <Changelog />;
      default:
        return <p>Tableau de bord Displex.</p>;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-grow overflow-hidden">
        <Sidebar
          setActiveComponent={setActiveComponent}
          subscriptionType={subscriptionType}
        />
        <main className="bg-gray-50 py-8 px-8 overflow-y-auto w-full">
          {isLoading ? (
            <div className="text-center">Chargement en cours...</div>
          ) : (
            renderComponent()
          )}
        </main>
      </div>
      {modalContent.isOpen && (
        <GenericModal
          isOpen={modalContent.isOpen}
          onClose={() => setModalContent({ ...modalContent, isOpen: false })}
          title={modalContent.title}
          content={modalContent.content}
          linkPath={modalContent.linkPath}
          linkText={modalContent.linkText}
        />
      )}
      {isBuyPlayerModalOpen && (
        <BuyPlayerModal
          isOpen={isBuyPlayerModalOpen}
          onClose={() => setIsBuyPlayerModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Dashboard;
