import React, { useState, useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

const VideoModal = ({ videoUrl, onClose }) => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const hideTimer = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    console.log("VideoModal opened");
    console.log("Video URL:", videoUrl);

    // Gestionnaire d'événements pour la fermeture en cliquant à l'extérieur
    const handleCloseOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleCloseOutside);
    return () => {
      document.removeEventListener("mousedown", handleCloseOutside);
    };
  }, [onClose, videoUrl]);

  const handleMouseEnterVideo = () => {
    clearTimeout(hideTimer.current);
    setShowCloseButton(true);
    setOpacity(1);
  };

  const handleMouseLeaveVideo = () => {
    hideTimer.current = setTimeout(() => {
      setShowCloseButton(false);
      setOpacity(0);
    }, 180);
  };

  const handleMouseEnterButton = () => {
    clearTimeout(hideTimer.current);
  };

  const handleMouseLeaveButton = () => {
    hideTimer.current = setTimeout(() => {
      setShowCloseButton(false);
      setOpacity(0);
    }, 500);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-75">
      <div
        ref={modalRef}
        className="rounded-lg shadow-md w-2/3 max-w-2xl relative"
      >
        {showCloseButton && (
          <button
            onClick={onClose}
            onMouseEnter={handleMouseEnterButton}
            onMouseLeave={handleMouseLeaveButton}
            className="absolute top-0 right-0 mt-3 mr-3 text-xl text-white hover:bg-zinc-900 hover:bg-opacity-80 p-2 rounded-lg-full z-10"
            style={{ opacity: opacity, transition: "opacity 5s" }}
          >
            <FaTimes />
          </button>
        )}

        <div
          className="video-wrapper relative"
          style={{ paddingBottom: "56.25%" }}
          onMouseEnter={handleMouseEnterVideo}
          onMouseLeave={handleMouseLeaveVideo}
        >
          <video
            controls
            autoPlay
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <source src={videoUrl} type="video/mp4" />
            Votre navigateur ne prend pas en charge la lecture de vidéos.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
