import React from "react";
import { FaUpload } from "react-icons/fa";

const FileUpload = ({
  selectedFile,
  handleFileChange,
  uploadFile,
  uploadStatus,
  inputRef,
}) => (
  <div className="mt-4 flex flex-col items-center justify-center bg-white p-4 border border-gray-200 rounded-lg shadow-sm">
    <div className="flex flex-row w-full justify-between">
      <input
        id="file-upload"
        type="file"
        accept="video/mp4,image/jpeg,image/png"
        ref={inputRef}
        onChange={handleFileChange}
        className="hidden"
      />
      <label
        htmlFor="file-upload"
        className={`max-h-11 file-input-label mr-2 flex-grow p-2 border border-gray-300 text-center rounded-lg text-gray-700 bg-white hover:bg-gray-100 ${
          uploadStatus === "uploading"
            ? "opacity-50 cursor-not-allowed custom-pulse"
            : ""
        }`}
      >
        {selectedFile ? (
          <span className="sm:truncate sm:max-w-[80%] sm:inline-block">
            {selectedFile.name}
          </span>
        ) : (
          "Choisir un fichier"
        )}
      </label>
      <button
        onClick={uploadFile}
        className="hover-bg-white max-h-11 flex upload-button text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 hover:bg-white"
      >
        <FaUpload className="inline sm:mr-2 mr-0 mt-1" />
        <span className="hidden sm:inline">Télécharger</span>
      </button>
    </div>
  </div>
);

export default FileUpload;
