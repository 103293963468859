// Ne pas supprimer les commentaires suivants :
// 1. Si il y a qu'une seule image alors la lire en boucle sans coupure.
// 2. Si il y a qu'une seule vidéo alors la lire en boucle.
// 3. Quand la vérification de la playlist se fait, si il n'y a pas de changement alors continuer la lecture en cours.
// 4. Les images doivent être lues avec une valeur fixe de 10 secondes.
// 5. Les vidéos doivent être lues en entier avant de passer au média suivant, sauf dans le cas où la vidéo est supprimée du serveur aws, on passe donc au média suivant.
// 6. Vérifier toutes les 3 secondes les changements de la playlist à lire, si aucun changement alors continuer la lecture en cours.
// 7. Entre deux médias, que ce soit image ou vidéo, il doit y avoir un léger fondu à la fin du média qui se termine et celui qui commence.

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const WebPlayer = () => {
  const { deviceId } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaUrl, setMediaUrl] = useState("");
  const intervalRef = useRef(null);
  const videoRef = useRef(null);
  const imageTimeoutRef = useRef(null);
  const lastPlaylistRef = useRef(null);

  const fetchPlaylist = async () => {
    console.log("Fetching playlist...");
    try {
      const response = await axios.get(`${apiUrl}/playlist/device/${deviceId}`);
      if (response.data.length > 0) {
        const activePlaylist = response.data[0];

        console.log("Playlist fetched:", activePlaylist);

        // 3. Si la playlist n'a pas changé, continuer la lecture en cours.
        if (
          !lastPlaylistRef.current ||
          JSON.stringify(lastPlaylistRef.current) !==
            JSON.stringify(activePlaylist.mediaItems)
        ) {
          console.log("Playlist has changed or is new. Updating playlist...");
          lastPlaylistRef.current = activePlaylist.mediaItems;
          setPlaylist(activePlaylist);

          if (currentMediaIndex >= activePlaylist.mediaItems.length) {
            console.log(
              "Current media index exceeds playlist length. Resetting index to 0."
            );
            setCurrentMediaIndex(0);
          }

          if (activePlaylist.mediaItems.length > 0) {
            loadMedia(activePlaylist.mediaItems[currentMediaIndex]);
          } else {
            setMediaUrl("");
          }
        } else {
          console.log("Playlist has not changed. Continuing playback.");
        }
      } else {
        console.log("No playlist available for this device.");
        setPlaylist(null);
        setMediaUrl("");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de la playlist :", error);
    }
  };

  const loadMedia = async (mediaItem) => {
    if (!mediaItem) {
      console.error("No media item provided to loadMedia");
      return;
    }

    try {
      const { userId, fileName } = mediaItem;

      console.log("Loading media:", fileName);

      const response = await axios.get(
        `${apiUrl}/media/view/${userId}/${fileName}`
      );
      const signedUrl = response.data.url;

      applyFadeOut(() => {
        setMediaUrl(signedUrl);
      });
    } catch (error) {
      console.error(
        `Error loading media URL for ${mediaItem.fileName}:`,
        error
      );
      setMediaUrl("");
      handleMediaEnd(); // 5. Passer au média suivant si la vidéo est supprimée
    }
  };

  const updateOnlineStatus = async () => {
    try {
      console.log("Updating device online status...");
      const response = await axios.put(
        `${apiUrl}/player/update-online-status/${deviceId}`
      );
      if (response.status === 200) {
        console.log("Device lastOnline updated successfully");
      } else {
        console.error(
          `Error updating lastOnline: ${response.status}, ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Failed to update lastOnline:", error);
    }
  };

  useEffect(() => {
    if (deviceId) {
      fetchPlaylist();
      updateOnlineStatus();
    }

    intervalRef.current = setInterval(() => {
      console.log("Refreshing playlist and online status...");
      fetchPlaylist();
      updateOnlineStatus();
    }, 3000); // 6. Vérifier toutes les 3 secondes les changements de la playlist à lire

    return () => {
      console.log("Clearing intervals and timeouts on component unmount.");
      clearInterval(intervalRef.current);
      clearTimeout(imageTimeoutRef.current);
    };
  }, [deviceId]);

  useEffect(() => {
    if (playlist && playlist.mediaItems.length > 0) {
      const currentMedia = playlist.mediaItems[currentMediaIndex];
      console.log(
        "Continuing playback of current media:",
        currentMedia.fileName
      );
      loadMedia(currentMedia);
    }
  }, [playlist, currentMediaIndex]);

  const handleMediaEnd = () => {
    if (playlist && playlist.mediaItems.length > 0) {
      if (playlist.mediaItems.length === 1) {
        console.log("Replaying single media in playlist.");
        setCurrentMediaIndex(0);
        if (playlist.mediaItems[0].mediaType === "video") {
          videoRef.current.currentTime = 0;
          videoRef.current.play();
        } else {
          handleImageLoad(10);
        }
      } else {
        console.log("Moving to next media in playlist.");
        const nextIndex = (currentMediaIndex + 1) % playlist.mediaItems.length;
        setCurrentMediaIndex(nextIndex);
      }
    }
  };

  const handleImageLoad = (duration = 10) => {
    console.log(`Image loaded. Displaying for ${duration} seconds.`);
    applyFadeIn();
    imageTimeoutRef.current = setTimeout(handleMediaEnd, duration * 1000);
  };

  const handleDoubleClick = () => {
    console.log("Handling double-click for fullscreen...");
    try {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        console.log("Exiting fullscreen mode.");
      } else if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
        console.log("Entering fullscreen mode.");
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
        console.log("Entering fullscreen mode (Webkit).");
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
        console.log("Entering fullscreen mode (Moz).");
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
        console.log("Entering fullscreen mode (MS).");
      } else {
        throw new Error("Fullscreen API is not supported");
      }
    } catch (error) {
      console.error("Failed to enter fullscreen:", error.message);
    }
  };

  useEffect(() => {
    if (mediaUrl && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing video:", error.message);
      });
    }
  }, [mediaUrl]);

  const applyFadeOut = (callback) => {
    const mediaElement = videoRef.current || document.querySelector("img");
    if (mediaElement) {
      mediaElement.style.transition = "opacity 1s ease-in-out";
      mediaElement.style.opacity = 0;

      setTimeout(() => {
        if (callback) callback();
      }, 1000); // Attend que le fondu sortant soit terminé avant d'appeler le callback
    }
  };

  const applyFadeIn = () => {
    const mediaElement = videoRef.current || document.querySelector("img");
    if (mediaElement) {
      mediaElement.style.transition = "opacity 1s ease-in-out";
      mediaElement.style.opacity = 1;
    }
  };

  useEffect(() => {
    applyFadeIn();
  }, [mediaUrl]);

  return (
    <div
      className="bg-black h-screen flex justify-center items-center"
      onDoubleClick={handleDoubleClick}
    >
      {playlist && playlist.mediaItems.length > 0 ? (
        <div className="w-full h-full">
          {playlist.mediaItems[currentMediaIndex]?.mediaType === "video" ? (
            <video
              ref={videoRef}
              src={mediaUrl}
              autoPlay
              muted
              onEnded={handleMediaEnd}
              style={{ width: "100%", height: "100%" }}
              className="object-contain"
              controls={false}
              disablePictureInPicture
              controlsList="nodownload nofullscreen noremoteplayback"
              onTimeUpdate={(e) => {
                const currentTime = e.target.currentTime;
                const duration = e.target.duration;
                if (duration - currentTime < 1) {
                  applyFadeOut(); // Commence le fondu vers la fin de la vidéo
                }
              }}
            />
          ) : (
            <img
              src={mediaUrl}
              alt={playlist.mediaItems[currentMediaIndex]?.fileName || "Media"}
              className="object-contain w-full h-full"
              onLoad={() => {
                applyFadeIn(); // Appliquer le fondu entrant à l'image
                handleImageLoad();
              }}
            />
          )}
        </div>
      ) : (
        <div className="text-white">Aucun média à afficher.</div>
      )}
    </div>
  );
};

export default WebPlayer;
