import React from "react";

const Changelog = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Changelog</h1>
      <ul>
        <li>
          <strong>v1.0.0</strong> - Initial release
          <ul className="list-disc ml-6">
            <li>Feature 1</li>
            <li>Feature 2</li>
            <li>Bug Fixes</li>
          </ul>
        </li>
        {/* Add more versions as needed */}
      </ul>
    </div>
  );
};

export default Changelog;
