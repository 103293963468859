import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { FaPlus, FaMinus } from "react-icons/fa";

// Define base URLs
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const BasicManagerModal = ({ isOpen, onClose, device }) => {
  const { user } = useUser();
  const [playlist, setPlaylist] = useState(null);
  const [playlistMediaFiles, setPlaylistMediaFiles] = useState([]);
  const [libraryMediaFiles, setLibraryMediaFiles] = useState([]);
  const [thumbnailUrls, setThumbnailUrls] = useState({});
  const [isLimitReached, setIsLimitReached] = useState(false);
  const modalRef = useRef(null);

  const fetchLibraryMedias = async () => {
    try {
      console.log("Fetching library media files...");
      const clerkUserId = user.id;
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${clerkUserId}`
      );
      console.log("User ID response:", userResponse.data);
      const userId = userResponse.data.userId;

      const response = await axios.get(`${apiUrl}/media/list/${userId}`);
      console.log("Library media files response:", response.data);
      setLibraryMediaFiles(response.data);

      const thumbnails = {};
      response.data.forEach((media) => {
        thumbnails[
          media.fileName
        ] = `${apiUrl}/media/thumbnail/${userId}/${media.fileName}`;
      });
      setThumbnailUrls(thumbnails);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des fichiers multimédias :",
        error
      );
    }
  };

  const fetchPlaylistMedia = async (playlistId) => {
    try {
      console.log(`Fetching media for playlist ID: ${playlistId}`);
      const response = await axios.get(
        `${apiUrl}/playlist/media/${playlistId}`
      );
      console.log("Playlist media response:", response.data);
      setPlaylistMediaFiles(response.data.mediaItems);
      setIsLimitReached(response.data.mediaItems.length >= 20);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des médias de la playlist :",
        error
      );
    }
  };

  const fetchOrCreatePlaylist = async () => {
    try {
      console.log("Fetching or creating playlist...");
      const clerkUserId = user.id;
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${clerkUserId}`
      );
      console.log("User ID response:", userResponse.data);
      const userId = userResponse.data.userId;

      const response = await axios.post(
        `${apiUrl}/playlist/basic/create-default`,
        {
          userId,
          deviceId: device._id,
        }
      );

      console.log("Playlist fetch/create response:", response.data);
      setPlaylist(response.data);
      fetchPlaylistMedia(response.data._id);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération ou de la création de la playlist :",
        error
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      console.log("Modal is open, fetching or creating playlist...");
      fetchOrCreatePlaylist();
    }
  }, [isOpen]);

  useEffect(() => {
    if (playlist) {
      console.log("Playlist is set, fetching library media files...");
      fetchLibraryMedias();
    }
  }, [playlist]);

  const handleAddMediaToPlaylist = async (mediaId) => {
    try {
      console.log(
        `Adding media ID ${mediaId} to playlist ID ${playlist._id}...`
      );
      const response = await axios.post(
        `${apiUrl}/playlist/add-media/${playlist._id}`,
        {
          mediaId,
        }
      );
      console.log("Add media to playlist response:", response.data);
      fetchPlaylistMedia(playlist._id);
    } catch (error) {
      console.error("Erreur lors de l'ajout du média à la playlist :", error);
    }
  };

  const handleRemoveMediaFromPlaylist = async (mediaId) => {
    try {
      console.log(
        `Removing media ID ${mediaId} from playlist ID ${playlist._id}...`
      );
      const response = await axios.delete(
        `${apiUrl}/playlist/remove-media/${playlist._id}/${mediaId}`
      );
      console.log("Remove media from playlist response:", response.data);
      fetchPlaylistMedia(playlist._id);
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du média de la playlist :",
        error
      );
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        console.log("Outside click detected, closing modal.");
        onClose();
      }
    };

    if (isOpen) {
      console.log("Adding event listener for outside clicks...");
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      console.log("Removing event listener for outside clicks...");
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Filtrer les fichiers multimédias pour exclure ceux qui sont déjà dans la playlist
  const filteredLibraryMediaFiles = libraryMediaFiles.filter(
    (media) => !playlistMediaFiles.some((item) => item._id === media._id)
  );

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-white p-8 rounded-lg shadow-2xl w-11/12 md:w-2/3 lg:w-1/2"
      >
        {playlist ? (
          <>
            <h2 className="text-xl font-semibold mb-3 mt-6">
              Médias de la playlist :
            </h2>
            <ul className="mb-4 max-h-60 overflow-y-auto">
              {playlistMediaFiles.length > 0 ? (
                playlistMediaFiles.map((media, index) => (
                  <li
                    key={index}
                    className="py-2 border-b flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <div className="w-16 h-10 mr-4">
                        <img
                          src={thumbnailUrls[media.fileName]}
                          alt="Miniature"
                          className="h-auto max-h-full max-w-full object-cover"
                          onError={(e) => (e.target.onerror = null)}
                        />
                      </div>
                      <span className="align-middle overflow-hidden text-ellipsis">
                        {media.fileName || "Nom du fichier manquant"}
                      </span>
                    </div>
                    <button
                      className="ml-auto p-2 bg-red-500 rounded-lg hover:bg-red-700 transition duration-300 flex items-center justify-center mr-3"
                      style={{ width: "30px", height: "30px" }}
                      onClick={() => {
                        console.log(
                          `Attempting to remove media ID: ${media._id}`
                        );
                        handleRemoveMediaFromPlaylist(media._id);
                      }}
                    >
                      <FaMinus
                        className="text-white"
                        style={{ fontSize: "9px" }}
                      />
                    </button>
                  </li>
                ))
              ) : (
                <li className="py-2">Aucun média dans la playlist actuelle.</li>
              )}
            </ul>
            <div className="mt-4">
              <div className="mb-2">
                <h3 className="text-xl font-semibold">
                  Bibliothèque de médias
                </h3>
                {isLimitReached && (
                  <p className="text-sm" style={{ color: "red" }}>
                    La limite de 20 médias dans cette playlist a été atteinte.
                  </p>
                )}
              </div>
              <ul className="max-h-60 overflow-y-auto">
                {filteredLibraryMediaFiles.length > 0 ? (
                  filteredLibraryMediaFiles.map((media, index) => (
                    <li
                      key={index}
                      className="flex items-center mb-2 justify-between"
                    >
                      <div className="flex items-center">
                        <div className="w-16 h-10 mr-4">
                          <img
                            src={thumbnailUrls[media.fileName]}
                            alt="Miniature"
                            className="h-auto max-h-full max-w-full object-cover"
                          />
                        </div>
                        <span className="align-middle overflow-hidden text-ellipsis">
                          {media.fileName || "Nom du fichier manquant"}
                        </span>
                      </div>
                      <button
                        disabled={isLimitReached}
                        className={`py-2 px-2 ${
                          isLimitReached
                            ? "bg-gray-400 hover:bg-gray-400"
                            : "bg-green-500"
                        } rounded-lg hover:bg-green-700 transition duration-300 flex items-center justify-center mr-3`}
                        style={{ width: "30px", height: "30px" }}
                        onClick={() => {
                          console.log(
                            `Attempting to add media ID: ${media._id}`
                          );
                          handleAddMediaToPlaylist(media._id);
                        }}
                      >
                        <FaPlus
                          className="text-white"
                          style={{ fontSize: "12px" }}
                        />
                      </button>
                    </li>
                  ))
                ) : (
                  <p>Aucun fichier média trouvé.</p>
                )}
              </ul>
            </div>
          </>
        ) : (
          <p>Chargement de la playlist...</p>
        )}
        <div className="flex justify-end pt-4">
          <button
            className="custom-button text-white py-2 px-4 rounded-lg"
            onClick={onClose}
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default BasicManagerModal;
