import React from "react";

const EnterpriseCard = () => {
  const handleContactClick = () => {
    window.open("https://tally.so/r/wLGod2", "_blank");
  };

  return (
    <div className="bg-white bg-opacity-100 rounded-lg overflow-hidden shadow-lg border-solid border-gray-200 border mt-8 p-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-4 text-center">
        Besoin d'une formule entreprise ?
      </h2>
      <p className="text-md text-gray-700 mb-6 text-center">
        Contactez-nous pour discuter de solutions adaptées à vos besoins
        spécifiques et bénéficier de nos offres pour les entreprises.
      </p>
      <div className="text-center">
        <button
          className="text-white font-bold py-2 px-4 rounded-lg bg-gradient-to-r from-indigo-500 to-pink-500 hover-bg-white transition duration-200"
          onClick={handleContactClick}
        >
          Contactez-nous
        </button>
      </div>
    </div>
  );
};

export default EnterpriseCard;
