import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Main/Header";
import "../../displex-style.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const PaymentSuccess = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const query = useQuery();
  const stripeSessionId = query.get("session_id");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (stripeSessionId) {
        try {
          const response = await axios.get(
            `${apiUrl}/order/infos/${stripeSessionId}`
          );
          setOrderDetails(response.data);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de la commande",
            error
          );
        }
      } else {
        console.log("Aucun stripeSessionId fourni au composant PaymentSuccess");
      }
    };

    fetchOrderDetails();
  }, [stripeSessionId]);

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <main className="flex-grow flex items-center justify-center">
        <div className="max-w-3xl w-full p-8 bg-white shadow-md rounded-lg">
          {orderDetails ? (
            <div className="text-center">
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
                Paiement Réussi
              </h2>
              <p className="text-xl text-gray-700 mb-4">
                Merci, {orderDetails.firstName}, pour votre achat !
              </p>
              <p className="text-lg text-gray-700 mb-4">
                Total HT de la commande :{" "}
                {(orderDetails.totalPrice / 100 / 1.2).toFixed(2)} €
              </p>
              {orderDetails.items && orderDetails.items.length > 0 && (
                <div className="text-left">
                  <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                    Détails de la commande :
                  </h3>
                  <ul className="text-gray-700">
                    {orderDetails.items.map((item, index) => (
                      <li key={index} className="mb-2">
                        <span className="font-medium">{item.name}</span> -
                        Quantité : {item.quantity}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <button
                onClick={handleBackToHome}
                className="hover-bg-white max-h-11 upload-button text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 mt-6"
              >
                Retour au tableau de bord
              </button>
            </div>
          ) : (
            <p className="text-center text-gray-700">
              Chargement des détails de la commande...
            </p>
          )}
        </div>
      </main>
    </div>
  );
};

export default PaymentSuccess;
