import React, { useState, useEffect, useRef } from "react";
import { FaTrash, FaEllipsisV, FaTimes, FaPen } from "react-icons/fa";
import RenameModal from "../Modals/RenameModal"; // Import de la modal
import { useSwipeable } from "react-swipeable"; // Import du hook de swipe

const MediaItem = ({
  media,
  index,
  thumbnailUrl,
  openMediaModal,
  confirmDelete,
  actionMenuOpen,
  toggleActionMenu,
  renameMedia, // Nouvelle fonction pour renommer le fichier
}) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const [isSwiped, setIsSwiped] = useState(false);
  const actionMenuRef = useRef(null); // Ref pour détecter les clics en dehors du menu
  const swipeRef = useRef(null); // Ref pour détecter les clics en dehors après le swipe

  // Fonction pour extraire le nom du fichier après le premier tiret
  const getFormattedFileName = (fileName) => {
    const parts = fileName.split("-");
    return parts.length > 1 ? parts.slice(1).join("-") : fileName;
  };

  // Fonction pour renommer le fichier
  const handleRename = (newFileName) => {
    const extension = media.fileName.split(".").pop(); // Ex: 'mp4'
    const prefix = media.fileName.split("-")[0]; // Ex: '123456'
    const finalFileName = `${prefix}-${newFileName}.${extension}`;
    renameMedia(media.fileName, finalFileName);
    setIsRenaming(false);
  };

  // Utilisation de react-swipeable pour capturer le swipe
  const handlers = useSwipeable({
    onSwipedLeft: () => setIsSwiped(true), // Affiche l'icône poubelle
    onSwipedRight: () => setIsSwiped(false), // Cache l'icône poubelle
    preventDefaultTouchmoveEvent: true,
    trackMouse: false, // Optionnel : Permet aussi d'utiliser la souris
  });

  // Ferme le menu d'action lorsque l'utilisateur clique en dehors
  const handleClickOutsideMenu = (event) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target)
    ) {
      toggleActionMenu(null);
    }
  };

  // Ferme l'icône poubelle si l'utilisateur clique en dehors après un swipe
  const handleClickOutsideSwipe = (event) => {
    if (swipeRef.current && !swipeRef.current.contains(event.target)) {
      setIsSwiped(false);
    }
  };

  useEffect(() => {
    if (actionMenuOpen === index) {
      document.addEventListener("mousedown", handleClickOutsideMenu);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [actionMenuOpen]);

  useEffect(() => {
    if (isSwiped) {
      document.addEventListener("mousedown", handleClickOutsideSwipe);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideSwipe);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSwipe);
    };
  }, [isSwiped]);

  return (
    <div {...handlers} className="relative flex flex-row items-center border-b">
      <div className="flex-none w-1/6 md:w-1/12 sm:p-1 my-1 mr-3 flex items-center justify-left">
        <img
          src={thumbnailUrl || "/path/to/default-image.png"}
          alt="Miniature"
          className="w-auto max-w-full max-h-[50px] cursor-pointer flex-shrink-0"
          onClick={() => openMediaModal(media.fileName, media.mediaType)}
        />
      </div>
      <div className="flex-grow w-4/6 md:w-11/12 py-3 sm:overflow-hidden overflow-scroll sm:truncate">
        <span className="truncate">{getFormattedFileName(media.fileName)}</span>
      </div>

      {/* Supprimer avec un swipe vers la gauche */}
      {isSwiped && (
        <div
          ref={swipeRef}
          className="absolute right-0 top-0 bottom-0 bg-red-500 flex items-center justify-center px-4 z-50"
        >
          <button
            onClick={() => confirmDelete(media.fileName)}
            className="text-white"
          >
            <FaTrash className="text-white text-2xl" />
          </button>
        </div>
      )}

      <div className="flex-none w-1/6 md:w-1/12 py-3 sm:pr-3 pr-1 text-right relative">
        <button
          onClick={() => toggleActionMenu(index)}
          className="text-gray-500 hover:text-gray-700 action-menu-trigger"
        >
          {actionMenuOpen === index ? <FaTimes /> : <FaEllipsisV />}
        </button>
        {actionMenuOpen === index && (
          <div
            ref={actionMenuRef}
            className="absolute right-0 mt-2 py-2 w-32 bg-white rounded-lg shadow-xl z-20 action-menu"
          >
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onClick={() => {
                setIsRenaming(true);
                toggleActionMenu(null); // Ferme le menu d'action lorsque la modal s'ouvre
              }}
            >
              <FaPen className="inline mr-2 mb-1" />
              Renommer
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onClick={() => confirmDelete(media.fileName)}
            >
              <FaTrash className="inline mr-2 mb-1" />
              Supprimer
            </button>
          </div>
        )}
      </div>

      {/* Afficher la modal pour renommer */}
      <RenameModal
        isOpen={isRenaming}
        onClose={() => setIsRenaming(false)}
        onRename={handleRename}
        currentFileName={getFormattedFileName(media.fileName)}
      />
    </div>
  );
};

export default MediaItem;
