import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { FaPlus, FaMinus } from "react-icons/fa";

// Define base URLs
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const AddMediaModal = ({ isOpen, onCancel, onSave, playlistId }) => {
  const { user } = useUser();
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [playlistMediaFiles, setPlaylistMediaFiles] = useState([]);
  const [libraryMediaFiles, setLibraryMediaFiles] = useState([]);
  const [thumbnailUrls, setThumbnailUrls] = useState({});
  const [isLimitReached, setIsLimitReached] = useState(false);
  const modalRef = useRef(null);

  const fetchLibraryMedias = async (playlistMedia) => {
    try {
      const clerkUserId = user.id;
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${clerkUserId}`
      );
      const userId = userResponse.data.userId;

      const response = await axios.get(`${apiUrl}/media/list/${userId}`);

      // Filtrer les médias qui ne sont pas déjà dans la playlist
      const filteredLibraryMedias = response.data.filter(
        (libraryMedia) =>
          !playlistMedia.some(
            (playlistMedia) => playlistMedia._id === libraryMedia._id
          )
      );

      setLibraryMediaFiles(filteredLibraryMedias);

      const thumbnails = {};
      filteredLibraryMedias.forEach((media) => {
        thumbnails[
          media.fileName
        ] = `${apiUrl}/media/thumbnail/${userId}/${media.fileName}`;
      });

      // Mise à jour des miniatures pour les médias dans la bibliothèque
      setThumbnailUrls((prevThumbnails) => ({
        ...prevThumbnails,
        ...thumbnails,
      }));
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des fichiers multimédias :",
        error
      );
    }
  };

  const fetchPlaylistMedia = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/playlist/media/${playlistId}`
      );
      const playlistMedia = response.data.mediaItems;
      setPlaylistMediaFiles(playlistMedia);
      setIsLimitReached(playlistMedia.length >= 20);

      // Après avoir récupéré les médias de la playlist, mettez à jour la bibliothèque de médias
      fetchLibraryMedias(playlistMedia);

      // Mise à jour des miniatures pour les médias dans la playlist
      const clerkUserId = user.id;
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${clerkUserId}`
      );
      const userId = userResponse.data.userId;

      const thumbnails = {};
      playlistMedia.forEach((media) => {
        thumbnails[
          media.fileName
        ] = `${apiUrl}/media/thumbnail/${userId}/${media.fileName}`;
      });

      setThumbnailUrls((prevThumbnails) => ({
        ...prevThumbnails,
        ...thumbnails,
      }));
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des médias de la playlist :",
        error
      );
    }
  };

  const handleAddMediaToPlaylist = async (mediaId, playlistId) => {
    try {
      const response = await axios.post(
        `${apiUrl}/playlist/add-media/${playlistId}`,
        {
          mediaId,
        }
      );

      // Rafraîchissez les médias de la playlist et la bibliothèque après l'ajout
      fetchPlaylistMedia();
      onSave();
    } catch (error) {
      console.error("Erreur lors de l'ajout du média à la playlist :", error);
    }
  };

  const handleRemoveMediaFromPlaylist = async (mediaId, playlistId) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/playlist/remove-media/${playlistId}/${mediaId}`
      );

      // Rafraîchissez les médias de la playlist et la bibliothèque après la suppression
      fetchPlaylistMedia();
      onSave();
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du média de la playlist :",
        error
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchPlaylistMedia(); // Charge les médias de la playlist et la bibliothèque
    }
  }, [isOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onCancel]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-white p-8 rounded-lg shadow-2xl w-11/12 md:w-2/3 lg:w-1/2"
      >
        <h2 className="text-xl font-semibold mb-3 mt-6">
          Médias de la playlist :
        </h2>
        <ul className="mb-4 max-h-60 overflow-y-auto">
          {playlistMediaFiles.length > 0 ? (
            playlistMediaFiles.map((media, index) => (
              <li
                key={index}
                className="py-2 border-b flex items-center justify-between"
              >
                <div className="flex items-center">
                  <div className="w-16 h-10 mr-4">
                    <img
                      src={thumbnailUrls[media.fileName]}
                      alt="Miniature"
                      className="h-auto max-h-full max-w-full object-cover"
                    />
                  </div>
                  <span className="align-middle overflow-hidden text-ellipsis">
                    {media.fileName}
                  </span>
                </div>
                <button
                  className="ml-auto p-2 bg-red-500 rounded-lg hover:bg-red-700 transition duration-300 flex items-center justify-center mr-3"
                  style={{ width: "30px", height: "30px" }}
                  onClick={() =>
                    handleRemoveMediaFromPlaylist(media._id, playlistId)
                  }
                >
                  <FaMinus className="text-white" style={{ fontSize: "9px" }} />
                </button>
              </li>
            ))
          ) : (
            <li className="py-2">Aucun média dans la playlist actuelle.</li>
          )}
        </ul>
        {showMediaLibrary && (
          <div className="mt-4">
            <div className="mb-2">
              <h3 className="text-xl font-semibold">Bibliothèque de médias</h3>
              {isLimitReached && (
                <p className="text-sm" style={{ color: "red" }}>
                  La limite de 20 médias dans cette playlist a été atteinte.
                </p>
              )}
            </div>
            <ul className="max-h-60 overflow-y-auto">
              {libraryMediaFiles.length > 0 ? (
                libraryMediaFiles.map((media, index) => (
                  <li
                    key={index}
                    className="flex items-center mb-2 justify-between"
                  >
                    <div className="flex items-center">
                      <div className="w-16 h-10 mr-4">
                        <img
                          src={thumbnailUrls[media.fileName]}
                          alt="Miniature"
                          className="h-auto max-h-full max-w-full object-cover"
                        />
                      </div>
                      <span className="align-middle overflow-hidden text-ellipsis">
                        {media.fileName}
                      </span>
                    </div>
                    <button
                      disabled={isLimitReached}
                      className={`py-2 px-2 ${
                        isLimitReached
                          ? "bg-gray-400 hover:bg-gray-400"
                          : "bg-green-500"
                      } rounded-lg hover:bg-green-700 transition duration-300 flex items-center justify-center mr-3`}
                      style={{ width: "30px", height: "30px" }}
                      onClick={() =>
                        handleAddMediaToPlaylist(media._id, playlistId)
                      }
                    >
                      <FaPlus
                        className="text-white"
                        style={{ fontSize: "12px" }}
                      />
                    </button>
                  </li>
                ))
              ) : (
                <p>Aucun fichier média trouvé.</p>
              )}
            </ul>
          </div>
        )}
        <div className="flex justify-end pt-4">
          <button
            className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 mr-2 rounded-lg transition duration-200 ease-in-out"
            onClick={() => setShowMediaLibrary(!showMediaLibrary)}
          >
            {showMediaLibrary ? "Masquer les médias" : "Afficher les médias"}
          </button>
          <button
            className="bg-gray-900 hover:bg-gray-500 text-white py-2 px-4 rounded-lg"
            onClick={onCancel}
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMediaModal;
