// File: src/components/Modals/PlaylistModal/DeviceCard.js

import React from "react";

const DeviceCard = ({ device, isSelected, onSelect }) => {
  return (
    <div
      key={device._id}
      className={`flex items-center py-2 my-2 border rounded-lg transition-colors duration-200 ease-in-out cursor-pointer ${
        isSelected ? "bg-blue-100 border-blue-300" : "bg-white border-gray-300"
      }`}
      onClick={() => onSelect(device._id)}
    >
      <div className="flex-1">
        <p className="text-gray-800 ml-2">{device.name}</p>
        {device.description && (
          <p className="text-gray-500">{device.description}</p>
        )}
      </div>
    </div>
  );
};

export default DeviceCard;
