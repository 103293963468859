import React, { useState, useEffect } from "react";
import Header from "../components/Main/Header";
import productImage from "../images/player-2.png";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

// Define base URLs
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const BuyPlayer = () => {
  const { user } = useUser();
  const [quantity, setQuantity] = useState(1);
  const [usedSlots, setUsedSlots] = useState(0);
  const [availableSlots, setAvailableSlots] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchSlots = async () => {
      if (user?.id) {
        try {
          const response = await axios.post(
            `${apiUrl}/sub/check-subscription`,
            {
              clerkUserId: user.id,
            }
          );
          setUsedSlots(response.data.usedSlots);
          setAvailableSlots(response.data.availableSlots);
        } catch (error) {
          console.error("Error checking subscription status:", error);
        }
      }
    };

    fetchSlots();
  }, [user]);

  useEffect(() => {
    if (quantity > availableSlots - usedSlots) {
      setErrorMessage(
        `Vous allez dépasser le nombre de players gérables avec votre abonnement de ${
          quantity - (availableSlots - usedSlots)
        } unités. Vous pourrez dans l'onglet abonnement ajuster votre abonnement pour gérer les ${
          quantity - (availableSlots - usedSlots)
        } boitiers qui dépassent ceux dans votre abonnement actuel.`
      );
    } else {
      setErrorMessage("");
    }
  }, [quantity, usedSlots, availableSlots]);

  const handlePurchase = async () => {
    if (!user) {
      console.error("User information not available");
      return;
    }

    const primaryPhoneNumber = user.phoneNumbers.find(
      (p) => p.id === user.primaryPhoneNumberId
    )?.phoneNumber;

    const userInfo = {
      clerkId: user.id,
      email: user.primaryEmailAddress.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: primaryPhoneNumber,
    };

    try {
      await axios.post(`${apiUrl}/users/upsert`, userInfo);
      const stripePriceId = process.env.REACT_APP_STRIPE_PRICE_ID_PLAYER;
      const additionalPlayers = quantity - (availableSlots - usedSlots);
      const purchaseData = {
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        stripePriceId: stripePriceId,
        clerkUserId: userInfo.clerkId,
        phoneNumber: primaryPhoneNumber,
        quantity,
        additionalPlayers: additionalPlayers > 0 ? additionalPlayers : 0,
      };

      const sessionResponse = await axios.post(
        `${apiUrl}/stripe/create-checkout-session`,
        purchaseData
      );

      const session = sessionResponse.data;
      if (!session || !session.sessionId) {
        console.error("Stripe session ID is missing");
        return;
      }

      const stripe = window.Stripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );
      await stripe.redirectToCheckout({ sessionId: session.sessionId });
    } catch (error) {
      console.error("Error creating Stripe payment session:", error);
    }
  };

  const incrementQuantity = () => setQuantity((q) => q + 1);
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((q) => q - 1);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow flex items-center justify-center">
        <div className="max-w-6xl w-full px-4 sm:px-6 lg:px-8 py-12">
          <div className="items-center justify-center w-full h-full bg-white p-6 md:p-12 rounded-lg shadow-none flex flex-col lg:flex-row">
            <img
              src={productImage}
              alt="Displex Player"
              className="lg:w-1/3 w-full lg:h-auto h-64 object-contain"
            />
            <div className="lg:ml-12 mt-8 lg:mt-0">
              <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
                Displex Player
              </h2>
              <p className="text-gray-700 mb-4">
                Offrez-vous notre player compact : idéal pour diffuser vos
                visuels sur tout écran HDMI, il se gère facilement via une
                interface web. Simple, efficace, il révolutionne votre affichage
                numérique.
              </p>
              <p className="text-gray-500 mb-1">
                Inclus : Boitier de diffusion, Alimentation, Câble HDMI & RJ45.
              </p>
              <div className="text-2xl lg:text-3xl font-bold text-purple-600">
                €{79 * quantity}.00
              </div>
              <p className="text-sm text-gray-500">
                en stock • livraison gratuite
              </p>
              <div className="mt-4 flex items-center">
                <MinusCircleIcon
                  className="w-8 h-8 text-purple-600 cursor-pointer"
                  onClick={decrementQuantity}
                />
                <span className="mx-4 text-xl">{quantity}</span>
                <PlusCircleIcon
                  className="w-8 h-8 text-purple-600 cursor-pointer"
                  onClick={incrementQuantity}
                />
              </div>
              {errorMessage && (
                <p className="mt-4 text-red-600 font-semibold">
                  {errorMessage}
                </p>
              )}
              <div className="mt-6">
                <button
                  className={`bg-purple-600 text-white px-6 py-3 rounded-lg transition duration-300 ${
                    errorMessage
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-purple-700"
                  }`}
                  onClick={handlePurchase}
                  disabled={!!errorMessage}
                >
                  ACHETER
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BuyPlayer;
