import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import Modal from "../Modals/Modal";
import { FaCreditCard } from "react-icons/fa6";
import { toast } from "react-toastify";

// Define base URLs
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MySubscription = () => {
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", content: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionPrice, setSubscriptionPrice] = useState(null);
  const [subscriptionInterval, setSubscriptionInterval] = useState("");
  const [hasActiveSubscription, setHasActiveSubscription] = useState(null);
  const [additionalPlayers, setAdditionalPlayers] = useState(0);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/sub/check-subscription`, {
          clerkUserId: user.id,
        });
        setHasActiveSubscription(response.data.isActive);
      } catch (error) {
        console.error("Error checking subscription status:", error);
      } finally {
        setIsLoading(false);
      }
    };
    checkSubscriptionStatus();
  }, [user.id]);

  useEffect(() => {
    const checkSubscriptionRenewalStatus = async () => {
      if (hasActiveSubscription) {
        setIsLoading(true);
        try {
          const response = await axios.post(
            `${apiUrl}/sub/check-subscription-renewal`,
            {
              clerkUserId: user.id,
            }
          );
          setCancelAtPeriodEnd(response.data.cancelAtPeriodEnd);
          setSubscriptionEndDate(new Date(response.data.subscriptionEndDate));
          setSubscriptionPrice(response.data.subscriptionPrice);
          setSubscriptionInterval(response.data.subscriptionInterval);
        } catch (error) {
          console.error(
            "Erreur lors de la vérification du statut de renouvellement:",
            error
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    checkSubscriptionRenewalStatus();
  }, [hasActiveSubscription]);

  const handleRenewSubscription = async () => {
    try {
      await axios.post(`${apiUrl}/sub/restart-subscription`, {
        clerkUserId: user.id,
      });
      setCancelAtPeriodEnd(false);
    } catch (error) {
      console.error("Erreur lors du renouvellement de l'abonnement:", error);
    }
  };

  const confirmModal = async () => {
    if (modalContent.title === "Réactiver l'abonnement") {
      await handleRenewSubscription();
    }
    setShowModal(false);
  };

  const cancelModal = () => {
    setShowModal(false);
  };

  const handleManageSubscription = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/stripe/create-customer-portal-session`,
        {
          clerkUserId: user.id,
        }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error(
        "Erreur lors de la redirection vers le Customer Portal:",
        error
      );
    }
  };

  const handleCanvaAuth = async () => {
    try {
      const response = await axios.get(`${apiUrl}/canva/auth`, {
        params: { userId: user.id },
      });
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error("Erreur lors de l'authentification Canva :", error);
      toast.error("Erreur lors de l'authentification Canva");
    }
  };

  return (
    <div className="container mx-auto py-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center mb-4">
          <FaCreditCard className="text-2xl text-gray-800 mr-2 mt-1" />
          <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
            Mon Abonnement
          </h2>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md">
        {isLoading ? (
          <div className="text-center">Chargement en cours...</div>
        ) : (
          <div>
            {hasActiveSubscription === false ? (
              <Link
                to="/subscription"
                className="bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 rounded-lg transition duration-200 ease-in-out hover-bg-white"
              >
                Voir les abonnements
              </Link>
            ) : cancelAtPeriodEnd ? (
              <div>
                <p className="text-gray-700 mb-4">
                  Votre abonnement se termine le{" "}
                  <span className="font-semibold">
                    {subscriptionEndDate
                      ? subscriptionEndDate.toLocaleDateString("fr-FR", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })
                      : "N/A"}
                  </span>
                  .
                </p>
                {subscriptionPrice && subscriptionEndDate ? (
                  <button
                    onClick={() => setShowModal(true)}
                    className="bg-gradient-to-r from-indigo-500 to-pink-500 text-white font-bold py-2 px-4 rounded-lg mb-4 transition duration-200 ease-in-out hover-bg-white"
                  >
                    Réactiver l'abonnement
                  </button>
                ) : null}
              </div>
            ) : (
              <div>
                {subscriptionPrice &&
                  subscriptionEndDate &&
                  subscriptionInterval && (
                    <button
                      onClick={handleManageSubscription}
                      className="bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 rounded-lg transition duration-200 ease-in-out hover-bg-white"
                    >
                      Gérer l'abonnement
                    </button>
                  )}
              </div>
            )}
          </div>
        )}

        {showModal && (
          <Modal
            title={modalContent.title}
            content={modalContent.content}
            onConfirm={confirmModal}
            onCancel={cancelModal}
          />
        )}
      </div>
    </div>
  );
};

export default MySubscription;
