import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "../Modals/Modal";
import { useUser } from "@clerk/clerk-react";
import PlaylistModal from "../Modals/PlaylistModal/PlaylistModal";
import AddMediaModal from "../Modals/PlaylistModal/AddMediaModal";
import ActionMenuPortal from "../Main/ActionMenuPortal";
import VideoModal from "../Modals/VideoModal";
import ImageModal from "../Modals/ImageModal";
import "../../displex-style.css";
import {
  FaTrash,
  FaEllipsisV,
  FaTimes,
  FaThList,
  FaEdit,
} from "react-icons/fa";
import { MdPermMedia } from "react-icons/md";
import { LuPlusCircle, LuRefreshCcw } from "react-icons/lu";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MyPlaylists = () => {
  const { user } = useUser();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [actionMenuOpen, setActionMenuOpen] = useState(null);
  const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState(false);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);
  const actionMenuRef = useRef(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [playlistToDelete, setPlaylistToDelete] = useState(null);
  const [playlistToDeleteName, setPlaylistToDeleteName] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingPlaylist, setEditingPlaylist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const [playlistsMediaLoaded, setPlaylistsMediaLoaded] = useState({});
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentMediaUrl, setCurrentMediaUrl] = useState(null);
  const [currentMediaType, setCurrentMediaType] = useState(null);

  useEffect(() => {
    if (user?.id) {
      fetchUserId();
    }
  }, [user]);

  const fetchUserId = async () => {
    try {
      const clerkUserId = user.id;
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${clerkUserId}`
      );
      const userId = userResponse.data.userId;
      fetchPlaylists(userId);
    } catch (error) {
      toast.error("Erreur lors de la récupération de l'ID utilisateur.");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionMenuRef.current &&
        !actionMenuRef.current.contains(event.target)
      ) {
        setActionMenuOpen(null);
      }
    };

    if (actionMenuOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionMenuOpen]);

  useEffect(() => {
    const fetchPlaylistsAndThumbnails = async () => {
      if (user?.id) {
        try {
          const clerkUserId = user.id;
          const userResponse = await axios.get(
            `${apiUrl}/users/getUserId/${clerkUserId}`
          );
          const userId = userResponse.data.userId;
          const response = await axios.get(`${apiUrl}/playlist/list/${userId}`);

          console.log("Playlists fetched:", response.data);
          const fetchedPlaylists = response.data.map((playlist) => {
            const mediaItemsWithThumbnails = playlist.mediaItems.map(
              (media) => {
                const thumbnailUrl = `${apiUrl}/media/thumbnail/${userId}/${media.fileName}`;
                return { ...media, thumbnailUrl };
              }
            );
            return { ...playlist, mediaItems: mediaItemsWithThumbnails };
          });

          console.log("Playlists with thumbnails:", fetchedPlaylists);
          setPlaylists(fetchedPlaylists);
        } catch (error) {
          console.error(
            "Erreur lors du chargement des playlists et des miniatures: ",
            error
          );
          toast.error("Erreur lors du chargement des playlists.");
        }
      }
    };

    fetchPlaylistsAndThumbnails();
  }, [user?.id]);

  useEffect(() => {
    const newLoadingImages = {};
    playlists.forEach((playlist) => {
      playlist.mediaItems.forEach((media) => {
        newLoadingImages[media._id] = true;
      });
    });
    setLoadingImages(newLoadingImages);
  }, [playlists]);

  const fetchPlaylists = async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/playlist/list/${userId}`);
      console.log("Fetched playlists:", response.data);
      setPlaylists(response.data);
    } catch (error) {
      console.error("Erreur lors du chargement des playlists: ", error);
      toast.error("Erreur lors du chargement des playlists.");
    } finally {
      setLoading(false);
    }
  };

  const openMediaModal = async (fileName, mediaType) => {
    console.log("Requesting signed URL for media");
    console.log("File Name:", fileName);

    try {
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${user.id}`
      );
      const userId = userResponse.data.userId;
      console.log("User ID:", userId);

      const response = await axios.get(
        `${apiUrl}/media/view/${userId}/${fileName}`
      );
      const mediaUrl = response.data.url;

      console.log("Opening media modal");
      console.log("Media URL:", mediaUrl);
      console.log("Media Type:", mediaType);

      setCurrentMediaUrl(mediaUrl);
      setCurrentMediaType(mediaType);

      if (mediaType.startsWith("video")) {
        setIsVideoModalOpen(true);
      } else if (mediaType.startsWith("image")) {
        setIsImageModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      toast.error("Erreur lors de la récupération de l'URL signée");
    }
  };

  const closeVideoModal = () => {
    console.log("Closing video modal");
    setIsVideoModalOpen(false);
    setCurrentMediaUrl(null);
    setCurrentMediaType(null);
  };

  const closeImageModal = () => {
    console.log("Closing image modal");
    setIsImageModalOpen(false);
    setCurrentMediaUrl(null);
    setCurrentMediaType(null);
  };

  if (loading) {
    return <div className="text-center p-4">Chargement en cours...</div>;
  }

  const handleEditClick = (playlist, e) => {
    e.stopPropagation();
    console.log("Editing playlist:", playlist);
    setEditingPlaylist(playlist);
    setIsEditModalOpen(true);
  };

  const toggleActionMenu = (index, event) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Calcule la position verticale
    const shouldOpenUpwards = buttonRect.bottom + 150 > viewportHeight;
    const menuTopPosition = shouldOpenUpwards
      ? buttonRect.top - 150 // Si trop bas, ouvrir vers le haut
      : buttonRect.bottom; // Sinon, ouvrir vers le bas

    // Ajustez ces valeurs pour affiner la position
    const adjustedTop = menuTopPosition + (shouldOpenUpwards ? -0 : 0); // Ajustement vertical
    const adjustedRight = window.innerWidth - buttonRect.right + 20; // Ajustement horizontal

    setActionMenuOpen({
      index,
      position: {
        top: adjustedTop, // Position verticale ajustée
        right: adjustedRight, // Position horizontale ajustée
      },
    });
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) {
      return "Information non disponible";
    }
    const dateTime = new Date(dateTimeString);
    if (isNaN(dateTime)) {
      return "Date inconnue";
    }
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return dateTime.toLocaleString("fr-FR", options);
  };

  const deletePlaylist = async (playlistId) => {
    try {
      await axios.delete(`${apiUrl}/playlist/delete/${playlistId}`);
      toast.success("Playlist supprimée avec succès");
      setPlaylists(playlists.filter((playlist) => playlist._id !== playlistId));
      setActionMenuOpen(null);
      setIsDeleteModalOpen(false);
    } catch (error) {
      toast.error("Erreur lors de la suppression de la playlist");
    }
  };

  const refreshPlaylists = async () => {
    if (user?.id) {
      try {
        const clerkUserId = user.id;
        const userResponse = await axios.get(
          `${apiUrl}/users/getUserId/${clerkUserId}`
        );
        const userId = userResponse.data.userId;
        const response = await axios.get(`${apiUrl}/playlist/list/${userId}`);
        const playlists = response.data;

        console.log("Refreshed playlists:", playlists);
        const updatedPlaylists = await Promise.all(
          playlists.map(async (playlist) => {
            const mediaItemsWithThumbnails = await Promise.all(
              playlist.mediaItems.map(async (media) => {
                const thumbnailUrl = `${apiUrl}/media/thumbnail/${userId}/${media.fileName}`;
                return { ...media, thumbnailUrl };
              })
            );
            return { ...playlist, mediaItems: mediaItemsWithThumbnails };
          })
        );

        console.log("Updated playlists with thumbnails:", updatedPlaylists);
        setPlaylists(updatedPlaylists);
      } catch (error) {
        console.error("Erreur lors de la mise à jour des playlists", error);
        toast.error("Erreur lors du chargement des playlists.");
      }
    }
  };

  const confirmDeletePlaylist = (playlistId, playlistName) => {
    setPlaylistToDelete(playlistId);
    setPlaylistToDeleteName(playlistName);
    setIsDeleteModalOpen(true);
  };

  const handleImageLoaded = (mediaId, playlistId) => {
    setImagesLoaded((prev) => ({ ...prev, [mediaId]: true }));
    setLoadingImages((prev) => ({ ...prev, [mediaId]: false }));

    const playlist = playlists.find((p) => p._id === playlistId);
    if (playlist) {
      const allMediaLoaded = playlist.mediaItems.every(
        (media) => imagesLoaded[media._id]
      );

      if (allMediaLoaded) {
        setPlaylistsMediaLoaded((prev) => ({ ...prev, [playlistId]: true }));
      }
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const formatTime = (timeString) => {
    if (timeString === "24:00") {
      return "00h00";
    }
    const [hours, minutes] = timeString.split(":");
    return `${hours}h${minutes}`;
  };

  const handleRefreshClick = async (playlistId) => {
    try {
      const response = await fetch(
        `${apiUrl}/playlist/update-last-updated/${playlistId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour de lastUpdated");
      }

      const updatedPlaylist = await response.json();
      console.log("Playlist mise à jour :", updatedPlaylist);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto py-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <FaThList className="text-2xl text-gray-800 mr-2" />
          <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
            Mes Playlists
          </h2>
        </div>
        <button
          className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-2.5 sm:px-4 rounded-lg transition duration-200 ease-in-out flex items-center hover:bg-white"
          onClick={() => setIsCreateModalOpen(true)}
        >
          <LuPlusCircle className="mt-0.5" />
          <span className="hidden sm:inline ml-2">Ajouter</span>
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 relative">
        {playlists.length > 0 ? (
          playlists.map((playlist, index) => {
            const startDateFormatted = formatDate(playlist.playDate.start);
            const endDateFormatted = formatDate(playlist.playDate.end);
            const startTimeFormatted = formatTime(playlist.playTime.start);
            const endTimeFormatted = formatTime(playlist.playTime.end);

            return (
              <div
                key={playlist._id}
                className="bg-white rounded-lg shadow-md p-4 relative transition-all duration-300 ease-in-out transform cursor-pointer"
                onClick={() => {
                  setSelectedPlaylistId(playlist._id);
                  setIsAddMediaModalOpen(true);
                }}
              >
                <h2 className="text-lg font-semibold">{playlist.name}</h2>
                <p className="text-sm">
                  {playlist.isRecurring ? (
                    <>
                      Lecture{" "}
                      <span className="font-semibold">tous les jours</span>{" "}
                      {playlist.playTime.start === "00:00" &&
                      playlist.playTime.end === "24:00" ? (
                        <span className="font-semibold">en non-stop</span>
                      ) : (
                        <>
                          de{" "}
                          <span className="font-semibold">
                            {startTimeFormatted}
                          </span>{" "}
                          à{" "}
                          <span className="font-semibold">
                            {endTimeFormatted}
                          </span>
                        </>
                      )}
                      .
                    </>
                  ) : (
                    <>
                      Lecture du{" "}
                      <span className="font-semibold">
                        {startDateFormatted}
                      </span>{" "}
                      au{" "}
                      <span className="font-semibold">{endDateFormatted}</span>.
                      <br />
                      {playlist.playTime.start === "00:00" &&
                      playlist.playTime.end === "24:00" ? (
                        <span className="font-semibold">en non-stop</span>
                      ) : (
                        <>
                          De{" "}
                          <span className="font-semibold">
                            {startTimeFormatted}
                          </span>{" "}
                          à{" "}
                          <span className="font-semibold">
                            {endTimeFormatted}
                          </span>
                        </>
                      )}
                      .
                    </>
                  )}
                </p>

                <div>
                  Diffusion sur :{" "}
                  {(playlist.deviceIds || []).reduce(
                    (prev, device, index, array) => [
                      ...prev,
                      <span
                        key={`${playlist._id}-device-${device._id}-${index}`}
                      >
                        {device.name}
                        {index < array.length - 1 ? ", " : "."}
                      </span>,
                    ],
                    []
                  )}
                </div>

                <div className="flex overflow-x-auto space-x-2 py-2">
                  {playlist.mediaItems.map((media, mediaIndex) => (
                    <img
                      key={`${playlist._id}-media-${media._id}-${mediaIndex}`}
                      className={`w-auto max-w-full max-h-[50px] cursor-pointer flex-shrink-0 transition-opacity duration-500 ${
                        imagesLoaded[media._id] ? "opacity-100" : "opacity-0"
                      }`}
                      onLoad={() => handleImageLoaded(media._id, playlist._id)}
                      src={media.thumbnailUrl}
                      alt={loadingImages[media._id] ? "" : "Miniature"}
                      style={{
                        display: imagesLoaded[media._id] ? "block" : "none",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Empêche l'événement de propagation
                        openMediaModal(media.fileName, media.mediaType);
                      }}
                    />
                  ))}
                </div>

                <div className="absolute top-0 right-0">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleActionMenu(index, e);
                    }}
                    className="text-gray-400 hover:text-gray-500 mr-3 mt-2 p-2 relative"
                  >
                    {actionMenuOpen && actionMenuOpen.index === index ? (
                      <FaTimes className="text-xl mt-1 ml-20" />
                    ) : (
                      <FaEllipsisV className="text-2xl rotate-90" />
                    )}
                  </button>
                  {actionMenuOpen && actionMenuOpen.index === index && (
                    <ActionMenuPortal>
                      <div
                        ref={actionMenuRef}
                        className="absolute py-2 w-32 bg-white rounded-lg-md shadow-xl"
                        style={{
                          top: `${actionMenuOpen.position.top}px`,
                          right: `${actionMenuOpen.position.right}px`,
                        }}
                      >
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedPlaylistId(playlist._id);
                            setIsAddMediaModalOpen(true);
                            setActionMenuOpen(null);
                          }}
                        >
                          <MdPermMedia className="inline mr-2 mb-1" />
                          Médias
                        </button>

                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={(e) => {
                            handleEditClick(playlist, e);
                            setActionMenuOpen(null);
                          }}
                        >
                          <FaEdit className="inline mr-2 mb-1" />
                          Modifier
                        </button>

                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRefreshClick(playlist._id);
                            setActionMenuOpen(null);
                          }}
                        >
                          <LuRefreshCcw className="inline mr-2 mb-1" />
                          Actualiser
                        </button>

                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmDeletePlaylist(playlist._id, playlist.name);
                            setActionMenuOpen(null);
                          }}
                        >
                          <FaTrash className="inline mr-2 mb-1" />
                          Supprimer
                        </button>
                      </div>
                    </ActionMenuPortal>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center">Vous n'avez pas encore de playlist.</p>
        )}
      </div>

      {isAddMediaModalOpen && (
        <AddMediaModal
          isOpen={isAddMediaModalOpen}
          onCancel={() => setIsAddMediaModalOpen(false)}
          onSave={() => {
            refreshPlaylists(); // Actualise seulement la liste des playlists sans fermer la modal
          }}
          playlistId={selectedPlaylistId}
        />
      )}

      {isCreateModalOpen && (
        <PlaylistModal
          onSave={() => {
            setIsCreateModalOpen(false); // Ferme la modal
            refreshPlaylists(); // Rafraîchit la liste des playlists
          }}
          onCancel={() => setIsCreateModalOpen(false)} // Ferme la modal sans rafraîchir
        />
      )}

      {isDeleteModalOpen && (
        <Modal
          title="Confirmer la suppression"
          content={`Êtes-vous sûr de vouloir supprimer la playlist "${playlistToDeleteName}" ?`}
          onConfirm={() => deletePlaylist(playlistToDelete)}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}

      {isEditModalOpen && (
        <PlaylistModal
          onSave={() => {
            setIsEditModalOpen(false); // Ferme la modal
            refreshPlaylists(); // Rafraîchit la liste des playlists
          }}
          onCancel={() => setIsEditModalOpen(false)}
          editingPlaylist={editingPlaylist}
          refreshPlaylists={refreshPlaylists} // Ajouté ici
        />
      )}

      {isVideoModalOpen && (
        <VideoModal videoUrl={currentMediaUrl} onClose={closeVideoModal} />
      )}
      {isImageModalOpen && (
        <ImageModal imageUrl={currentMediaUrl} onClose={closeImageModal} />
      )}
    </div>
  );
};

export default MyPlaylists;
