import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import MyPlayersBasic from "./MyPlayersBasic";
import MyPlayersAdvanced from "./MyPlayersAdvanced";
import PlayerTypeModal from "../Modals/PlayerTypeModal";
import BuyPlayerModal from "../Modals/BuyPlayerModal"; // Import de la modal BuyPlayer
import { FaTv } from "react-icons/fa6";
import { LuPlusCircle } from "react-icons/lu";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MyPlayers = () => {
  const { user } = useUser();
  const [subscriptionType, setSubscriptionType] = useState("");
  const [usedSlots, setUsedSlots] = useState(0);
  const [availableSlots, setAvailableSlots] = useState(0);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlayerTypeModalOpen, setIsPlayerTypeModalOpen] = useState(false);
  const [isBuyPlayerModalOpen, setIsBuyPlayerModalOpen] = useState(false); // State pour BuyPlayerModal

  useEffect(() => {
    if (user?.id) {
      fetchSubscriptionInfo(); // Récupère les informations d'abonnement pour afficher le bon composant
      fetchSlotInfo(); // Récupère les slots utilisés et disponibles
      fetchDevices(); // Récupère les devices
    }
  }, [user?.id]);

  // Récupère les informations d'abonnement pour afficher le bon composant
  const fetchSubscriptionInfo = () => {
    axios
      .post(`${apiUrl}/sub/check-subscription`, {
        clerkUserId: user.id,
      })
      .then((response) => {
        setSubscriptionType(response.data.subscriptionType);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching subscription:", error);
        setIsLoading(false);
      });
  };

  // Récupère les informations des slots utilisés et disponibles
  const fetchSlotInfo = () => {
    axios
      .get(`${apiUrl}/devices/slots/${user.id}`) // Utilise la nouvelle route backend
      .then((response) => {
        setUsedSlots(response.data.usedSlots);
        setAvailableSlots(response.data.availableSlots);
      })
      .catch((error) => {
        console.error("Error fetching slot information:", error);
      });
  };

  const fetchDevices = () => {
    axios
      .get(`${apiUrl}/devices/userDevices/${user.id}`)
      .then((response) => {
        setDevices(response.data.devices);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  };

  const handleAddPlayerClick = () => {
    setIsPlayerTypeModalOpen(true);
  };

  const handleOpenBuyPlayerModal = () => {
    setIsBuyPlayerModalOpen(true); // Ouvre la modal BuyPlayer
  };

  const handleCloseBuyPlayerModal = () => {
    setIsBuyPlayerModalOpen(false); // Ferme la modal BuyPlayer
  };

  const handleDeleteDevice = (deletedDeviceId) => {
    setDevices((prevDevices) =>
      prevDevices.filter((device) => device._id !== deletedDeviceId)
    );
    fetchSlotInfo(); // Recalcule les slots après suppression
  };

  const handlePlayerCreated = (newDevice) => {
    setDevices((prevDevices) => [...prevDevices, newDevice]);
    fetchSlotInfo(); // Recalcule les slots après création
  };

  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  return (
    <div className="container mx-auto py-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <div className="flex items-center mb-2 sm:mb-0">
          <FaTv className="text-2xl text-gray-800 mr-2 mt-1" />
          <h2 className="text-lg sm:text-2xl font-semibold text-gray-800">
            Mes Players
          </h2>
          <span className="ml-4 mt-1.5 text-md font-semibold text-gray-600">
            Slots : {usedSlots}/{availableSlots}
          </span>
        </div>
        <div className="flex">
          <button
            onClick={handleAddPlayerClick}
            className="hover-bg-white bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 rounded-lg transition duration-200 ease-in-out flex items-center"
          >
            <LuPlusCircle className="mr-2 mt-0.5" /> Ajouter
          </button>
        </div>
      </div>

      {subscriptionType.includes("Basic") ? (
        <MyPlayersBasic
          usedSlots={usedSlots}
          availableSlots={availableSlots}
          devices={devices}
          onDelete={handleDeleteDevice}
        />
      ) : subscriptionType.includes("Advanced") ? (
        <MyPlayersAdvanced
          usedSlots={usedSlots}
          availableSlots={availableSlots}
          devices={devices}
          setDevices={setDevices}
          onDelete={handleDeleteDevice}
        />
      ) : (
        <div>Type d'abonnement inconnu</div>
      )}

      {/* Modal for creating or buying a new player */}
      {isPlayerTypeModalOpen && (
        <PlayerTypeModal
          isOpen={isPlayerTypeModalOpen}
          onClose={() => setIsPlayerTypeModalOpen(false)}
          clerkId={user?.id}
          availableSlots={availableSlots}
          usedSlots={usedSlots}
          onPlayerCreated={(newDevice) => {
            if (newDevice) {
              handlePlayerCreated(newDevice);
            } else {
              handleOpenBuyPlayerModal(); // Ouvre la modal BuyPlayer si aucune création
            }
          }}
        />
      )}

      {/* Modal for buying a player */}
      {isBuyPlayerModalOpen && (
        <BuyPlayerModal
          isOpen={isBuyPlayerModalOpen}
          onClose={handleCloseBuyPlayerModal}
          clerkUserId={user.id}
          totalDevices={usedSlots}
        />
      )}
    </div>
  );
};

export default MyPlayers;
