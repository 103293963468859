import React, { useState, useEffect, useRef } from "react";
import { FaTimes, FaCheck } from "react-icons/fa";

const RenameModal = ({ isOpen, onClose, onRename, currentFileName }) => {
  const [newFileName, setNewFileName] = useState("");
  const modalRef = useRef(null);

  // Extraire le nom sans l'extension du fichier
  const getFileNameWithoutExtension = (fileName) => {
    return fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
  };

  // Mettre à jour le nom dans l'input quand la modal s'ouvre avec le fichier actuel
  useEffect(() => {
    if (currentFileName) {
      setNewFileName(getFileNameWithoutExtension(currentFileName));
    }
  }, [currentFileName]);

  // Fermer la modal si on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleRename = () => {
    if (
      newFileName.trim() &&
      newFileName !== getFileNameWithoutExtension(currentFileName)
    ) {
      onRename(newFileName);
    }
    onClose(); // Fermer la modal que le fichier soit renommé ou non
  };

  if (!isOpen) return null; // Ne pas rendre la modal si elle n'est pas ouverte

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Renommer le fichier</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </div>
        <div className="mb-4">
          <input
            type="text"
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            className="border w-full p-2 rounded"
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleRename}
            className="hover-bg-white max-h-11 flex upload-button text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 hover:bg-white"
          >
            <FaCheck className="mr-2 mt-1" /> Renommer
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenameModal;
