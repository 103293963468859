import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import SettingsModal from "../Modals/SettingsModal";
import { FaEdit } from "react-icons/fa";
import defaultImage from "../../images/device_type_D.png";
import deviceTypeAImage from "../../images/device_type_A.png";
import deviceTypeBImage from "../../images/device_type_B.png";
import deviceTypeCImage from "../../images/device_type_C.png";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MyPlayersAdvanced = ({
  usedSlots,
  availableSlots,
  devices,
  setDevices,
  onDelete,
}) => {
  const { user } = useUser();
  const [currentDevice, setCurrentDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  useEffect(() => {
    if (user?.id) {
      setIsLoading(true);
      fetchDevices();
    }
  }, [user?.id]);

  const fetchDevices = () => {
    axios
      .get(`${apiUrl}/devices/userDevices/${user.id}`)
      .then((response) => {
        setDevices(response.data.devices);
      })
      .catch((error) => {
        console.error("Error fetching devices:", error);
      })
      .finally(() => setIsLoading(false));
  };

  const getImageByType = (type) => {
    return (
      { A: deviceTypeAImage, B: deviceTypeBImage, C: deviceTypeCImage }[type] ||
      defaultImage
    );
  };

  const calculateOnlineStatus = (lastOnline) => {
    const lastOnlineDate = new Date(lastOnline);
    const now = new Date();
    const diffInSeconds = (now - lastOnlineDate) / 1000;
    return diffInSeconds <= 60;
  };

  const getOnlineStatusIndicator = (lastOnline) => {
    const isOnline = calculateOnlineStatus(lastOnline);
    const statusColor = isOnline ? "bg-green-500" : "bg-red-500";
    const textStatus = isOnline ? "En ligne" : "Hors ligne";
    return (
      <div className="flex items-center">
        <span className="mr-2">{textStatus}</span>
        <span
          className={`inline-block w-3 h-3 rounded-full ${statusColor}`}
          title={isOnline ? "Online" : "Offline"}
        />
      </div>
    );
  };

  const handleOpenSettingsModal = (device) => {
    setCurrentDevice(device);
    setSettingsModalOpen(true);
  };

  const handleCloseSettingsModal = () => {
    setSettingsModalOpen(false);
  };

  const handleDeleteDevice = (deletedDeviceId) => {
    onDelete(deletedDeviceId);
    setDevices((prevDevices) =>
      prevDevices.filter((device) => device._id !== deletedDeviceId)
    );
    fetchDevices();
    setSettingsModalOpen(false);
  };

  const handlePlayerImageClick = (e, device) => {
    e.stopPropagation();
    if (device.type === "B") {
      window.open(`/web-player/${device._id}`, "_blank");
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Chargement en cours...</div>
      ) : (
        <div className="space-y-4 w-full">
          {/* Suppression de la grid pour que chaque card prenne toute la largeur */}
          {devices.map((device) => (
            <div
              key={device._id}
              className="bg-white rounded-lg shadow-md p-4 mb-4 flex flex-col sm:flex-row sm:items-center justify-between w-full"
            >
              <div className="flex items-center">
                <img
                  src={getImageByType(device.type)}
                  alt="Device"
                  className={`h-16 w-16 object-cover mb-2 sm:mb-0 sm:mr-4 transition-transform transform ${
                    device.type === "B" ? "hover:scale-110 cursor-pointer" : ""
                  }`}
                  onClick={(e) => handlePlayerImageClick(e, device)}
                />
                <h3 className="text-lg font-semibold text-center sm:text-left">
                  {device.name}
                </h3>
              </div>
              <div className="flex justify-between items-center mt-2 sm:mt-0 sm:ml-auto">
                {getOnlineStatusIndicator(device.lastOnline)}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenSettingsModal(device);
                  }}
                  className="text-gray-400 hover:text-gray-500 ml-4 sm:ml-3" // Ajout du ml-4 pour mobile et ml-6 pour un espacement plus grand sur PC
                >
                  <FaEdit className="text-xl" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {settingsModalOpen && (
        <SettingsModal
          isOpen={settingsModalOpen}
          onClose={handleCloseSettingsModal}
          device={currentDevice}
          onDelete={handleDeleteDevice}
        />
      )}
    </>
  );
};

export default MyPlayersAdvanced;
