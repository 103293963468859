import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const OAuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const state = params.get("state");

      if (!code || !state) {
        console.error(
          "Authorization code or session state is missing from the URL"
        );
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/canva/oauth/callback`, {
          params: { code, state },
        });
        console.log("OAuth callback response:", response.data);
        const clerkId = response.data.clerkId;
        if (clerkId) {
          navigate(`/dashboard?clerkId=${clerkId}`);
        } else {
          console.error("Clerk ID is missing from the response");
        }
      } catch (error) {
        console.error("Error handling OAuth callback:", error);
      }
    };

    handleOAuthCallback();
  }, [navigate]);

  return <div>Loading...</div>;
};

export default OAuthCallback;
