import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const CalendarModal = ({ onSave, onCancel, eventData, deviceId, isEdit }) => {
  const { user } = useUser();
  const modalRef = useRef(null);
  const [playlistDetails, setPlaylistDetails] = useState({
    name: eventData?.title || "",
    startDate: eventData ? eventData.start.substring(0, 10) : "",
    endDate: eventData ? eventData.end.substring(0, 10) : "",
    startTime: eventData ? eventData.start.substring(11, 16) : "",
    endTime: eventData ? eventData.end.substring(11, 16) : "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (eventData) {
      console.log("Updating playlist details with eventData:", eventData);

      const start = new Date(eventData.start);
      const end = new Date(eventData.end);
      start.setHours(start.getHours() + 2); // Add 2 hours for display
      end.setHours(end.getHours() + 2); // Add 2 hours for display

      setPlaylistDetails({
        name: eventData.title || "",
        startDate: eventData.start.substring(0, 10),
        endDate: eventData.end.substring(0, 10),
        startTime: start.toISOString().substring(11, 16),
        endTime: end.toISOString().substring(11, 16),
      });
      console.log("Updated playlist details:", {
        name: eventData.title || "",
        startDate: eventData.start.substring(0, 10),
        endDate: eventData.end.substring(0, 10),
        startTime: start.toISOString().substring(11, 16),
        endTime: end.toISOString().substring(11, 16),
      });
    }
  }, [eventData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlaylistDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, [name]: value };

      // Ensure the end time is not earlier than the start time if the dates are the same
      if (
        name === "startTime" &&
        updatedDetails.startDate === updatedDetails.endDate &&
        (updatedDetails.endTime < value || updatedDetails.endTime === "23:59")
      ) {
        updatedDetails.endTime = value;
      }

      if (name === "startDate" && value !== updatedDetails.endDate) {
        updatedDetails.endTime = prevDetails.endTime;
      }

      return updatedDetails;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!playlistDetails.name.trim()) {
      setErrorMessage("Le nom de la playlist est requis.");
      return;
    }

    // Interdire la création de playlist avec la même heure de début et de fin
    if (playlistDetails.startTime === playlistDetails.endTime) {
      setErrorMessage(
        "L'heure de début et l'heure de fin ne peuvent pas être identiques."
      );
      return;
    }

    setErrorMessage("");
    const clerkUserId = user.id;

    try {
      const userResponse = await axios.get(
        `${apiUrl}/users/getUserId/${clerkUserId}`
      );
      const userId = userResponse.data.userId;

      const payload = {
        ...playlistDetails,
        userId,
        deviceIds: [deviceId],
      };

      console.log("Submitting payload:", payload);

      let response;
      if (isEdit) {
        payload._id = eventData.extendedProps.playlistId;
        response = await axios.put(
          `${apiUrl}/playlist/edit/${payload._id}`,
          payload
        );
        console.log("Update response:", response.data);
      } else {
        response = await axios.post(`${apiUrl}/playlist/create`, payload);
        console.log("Create response:", response.data);
      }
      onSave(response.data, isEdit);
      onCancel();
    } catch (error) {
      console.error("Erreur lors de la création de la playlist", error);
      setErrorMessage("Erreur lors de la création de la playlist");
    }
  };

  const handleDelete = async () => {
    if (isEdit) {
      try {
        const response = await axios.delete(
          `${apiUrl}/playlist/delete/${eventData.extendedProps.playlistId}`
        );
        console.log("Delete response:", response.data);
        onSave(null, isEdit);
        onCancel();
      } catch (error) {
        console.error("Erreur lors de la suppression de la playlist", error);
        setErrorMessage("Erreur lors de la suppression de la playlist");
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCancel]);

  const generateTimeOptions = (startTime) => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 5) {
        // Options toutes les 5 minutes
        const hour = i.toString().padStart(2, "0");
        const minute = j.toString().padStart(2, "0");
        const time = `${hour}:${minute}`;
        if (!startTime || time >= startTime) {
          times.push(time);
        }
      }
    }
    return times;
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-xl max-w-lg w-full"
      >
        <h2 className="text-xl font-semibold mb-6">
          {isEdit ? "Modifier la playlist" : "Création d'une playlist"}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom de la playlist :
            </label>
            <input
              type="text"
              name="name"
              value={playlistDetails.name}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            {errorMessage && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}
          </div>
          <div className="flex justify-between space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Date de début :
              </label>
              <input
                type="date"
                name="startDate"
                value={playlistDetails.startDate}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Date de fin :
              </label>
              <input
                type="date"
                name="endDate"
                value={playlistDetails.endDate}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
          <div className="flex justify-between space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Heure de début :
              </label>
              <select
                name="startTime"
                value={playlistDetails.startTime}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                {generateTimeOptions().map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Heure de fin :
              </label>
              <select
                name="endTime"
                value={playlistDetails.endTime}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                disabled={!playlistDetails.startTime}
              >
                {generateTimeOptions(
                  playlistDetails.startDate === playlistDetails.endDate
                    ? playlistDetails.startTime
                    : null
                ).map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            {isEdit && (
              <button
                type="button"
                onClick={handleDelete}
                className="inline-flex items-center justify-center py-2 px-4 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"
              >
                Supprimer
              </button>
            )}
            <button
              type="submit"
              className="bg-gradient-to-r from-indigo-500 to-pink-500 text-white py-2 px-4 rounded-lg transition duration-200 ease-in-out flex hover-bg-white"
            >
              Sauvegarder
            </button>

            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-900 text-white py-2 px-4 rounded-lg hover:bg-gray-500"
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CalendarModal;
