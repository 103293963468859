import React, { useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";

const ImageModal = ({ imageUrl, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    console.log("ImageModal opened");
    console.log("Image URL:", imageUrl);

    const handleCloseOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleCloseOutside);
    return () => {
      document.removeEventListener("mousedown", handleCloseOutside);
    };
  }, [onClose, imageUrl]);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-75">
      <div
        ref={modalRef}
        className="rounded-lg shadow-md w-2/3 max-w-2xl relative bg-white p-4"
      >
        <button
          onClick={onClose}
          className="absolute top-0 right-0 mt-3 mr-3 text-xl text-black hover:bg-gray-300 p-2 rounded-full z-10"
        >
          <FaTimes />
        </button>
        <div className="image-wrapper relative">
          <img
            src={imageUrl}
            alt="Media"
            className="object-contain w-full h-full"
            onError={(e) => {
              console.error("Error loading image:", e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
