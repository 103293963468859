import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  FaDesktop,
  FaImages,
  FaPlay,
  FaFileInvoice,
  FaCreditCard,
} from "react-icons/fa";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const Sidebar = ({ setActiveComponent }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("myPlayers");
  const [subscriptionType, setSubscriptionType] = useState("");

  useEffect(() => {
    if (user) {
      console.log("[Sidebar] User info: ", user);

      axios
        .post(`${apiUrl}/sub/check-subscription`, { clerkUserId: user.id })
        .then((response) => {
          console.log("[Sidebar] Full subscription response:", response.data);

          if (response.data && response.data.subscriptionType) {
            setSubscriptionType(response.data.subscriptionType);
            console.log(
              "[Sidebar] Subscription type set:",
              response.data.subscriptionType
            );
          } else {
            console.warn(
              "[Sidebar] Warning: subscriptionType is not found in the response"
            );
            setSubscriptionType("undefined");
          }
        })
        .catch((error) => {
          console.error("[Sidebar] Error fetching subscription info:", error);
        });
    }
  }, [user]);

  useEffect(() => {
    console.log("[Sidebar] Current subscription type:", subscriptionType);
    if (!subscriptionType) {
      console.warn("[Sidebar] Warning: Subscription type is not defined");
    }
  }, [subscriptionType]);

  const buttonStyle =
    "flex items-center gap-2 p-2 w-full text-left rounded-lg-md text-slate-600 transition-colors duration-200 ease-in-out cursor-pointer";
  const activeButtonStyle = "bg-gray-200 rounded";
  const iconStyle = "w-5 h-5 mx-auto md:mx-0";
  const textClass = "hidden md:block";

  const handleSetActiveComponent = (componentName) => {
    console.log("[Sidebar] Changing active component to: ", componentName);
    setActiveComponent(componentName);
    setActiveTab(componentName);
  };

  return (
    <div className="bg-white h-full flex-grow pt-10 w-16 pl-4 pr-4 md:w-64 flex flex-col justify-between border-r border-gray-300">
      <div>
        <nav>
          <div
            onClick={() => handleSetActiveComponent("myPlayers")}
            className={`${buttonStyle} ${
              activeTab === "myPlayers" ? activeButtonStyle : ""
            }`}
          >
            <FaDesktop className={iconStyle} />
            <span className={textClass}>Players</span>
          </div>
          <div
            onClick={() => handleSetActiveComponent("myMedias")}
            className={`${buttonStyle} ${
              activeTab === "myMedias" ? activeButtonStyle : ""
            }`}
          >
            <FaImages className={iconStyle} />
            <span className={textClass}>Médias</span>
          </div>
          {subscriptionType &&
            !["BasicMonthly", "BasicAnnually"].includes(subscriptionType) && (
              <div
                onClick={() => handleSetActiveComponent("myPlaylists")}
                className={`${buttonStyle} ${
                  activeTab === "myPlaylists" ? activeButtonStyle : ""
                }`}
              >
                <FaPlay className={iconStyle} />
                <span className={textClass}>Playlists</span>
              </div>
            )}
        </nav>
      </div>
      <div className="mb-3">
        <nav>
          <div
            onClick={() => handleSetActiveComponent("myInvoices")}
            className={`${buttonStyle} ${
              activeTab === "myInvoices" ? activeButtonStyle : ""
            }`}
          >
            <FaFileInvoice className={iconStyle} />
            <span className={textClass}>Factures</span>
          </div>
          <div
            onClick={() => handleSetActiveComponent("mySubscription")}
            className={`${buttonStyle} ${
              activeTab === "mySubscription" ? activeButtonStyle : ""
            }`}
          >
            <FaCreditCard className={iconStyle} />
            <span className={textClass}>Abonnement</span>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
