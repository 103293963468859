import React from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Tooltip } from "react-tooltip";

const SubscriptionCard = ({
  plan,
  onSubscribe,
  type,
  additionalPlayers,
  incrementPlayers,
  decrementPlayers,
}) => {
  const isAnnual = plan === "annual";

  const additionalPlayerPrice = isAnnual ? 4 : 5;

  const details = {
    basic: {
      title: "Basique",
      basePrice: isAnnual ? 19 : 24,
      billedText: isAnnual
        ? "/mois facturé annuellement"
        : "/mois facturé mensuellement",
      description: "Idéal pour les petits commerces et exposants.",
      basePlayers: 1,
      baseStorageMb: 250,
      features: [
        {
          text: "Diffusion d'image et de vidéo",
          description: "Diffusion en boucle de vos médias.",
        },
      ],
    },
    advanced: {
      title: "Avancé",
      basePrice: isAnnual ? 29 : 34,
      billedText: isAnnual
        ? "/mois facturé annuellement"
        : "/mois facturé mensuellement",
      description:
        "Un ciblage plus précis des publicités avec des fonctionnalités avancées.",
      basePlayers: 2,
      baseStorageMb: 500,
      features: [
        {
          text: "+ Programmation des diffusions",
          description: "Création et programmation des playlists.",
        },
      ],
    },
  };

  if (!details[type]) {
    return <div>Le type d'abonnement spécifié est invalide.</div>;
  }

  const {
    title,
    basePrice,
    billedText,
    description,
    basePlayers,
    baseStorageMb,
    features,
  } = details[type];
  const totalPrice = basePrice + additionalPlayers * additionalPlayerPrice;
  const playerFeature = `Gestion de ${basePlayers + additionalPlayers} player${
    basePlayers + additionalPlayers > 1 ? "s" : ""
  }.`;
  const storageFeature = `${
    baseStorageMb + additionalPlayers * 250
  } Mo de stockage.`;

  const reachedLimit = additionalPlayers >= 6;

  return (
    <div className="flex flex-col w-full bg-white rounded-lg overflow-hidden shadow-lg border-solid border-gray-200 border">
      <div className="p-8 flex flex-col flex-1">
        <div className="flex items-center">
          <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
            {title}
          </div>
          <div className="text-xs text-gray-500 ml-2">(vendu sans player physique)</div>
        </div>
        <div className="mt-1 text-lg leading-tight font-medium text-black">
          {description}
        </div>
        <div className="mt-4">
          <div className="text-5xl font-bold text-gray-900">{`${totalPrice}€`}</div>
          <div className="text-base text-gray-500 mb-4">{billedText}</div>
          <div className="flex flex-col justify-start flex-1">
            <ul className="space-y-2">
              <li>
                {playerFeature}
                <InformationCircleIcon
                  className="inline-block w-5 h-5 ml-2 text-gray-500"
                  data-tooltip-id={`${type}-playerTooltip`}
                />
                <Tooltip id={`${type}-playerTooltip`}>
                  Nombre de player que vous pouvez gérer.
                </Tooltip>
              </li>
              <li>
                {storageFeature}
                <InformationCircleIcon
                  className="inline-block w-5 h-5 ml-2 text-gray-500"
                  data-tooltip-id={`${type}-storageTooltip`}
                />
                <Tooltip id={`${type}-storageTooltip`}>
                  Espace de stockage pour vos médias.
                </Tooltip>
              </li>
              {features.map((feature, index) => (
                <li key={index}>
                  {feature.text}
                  <InformationCircleIcon
                    className="inline-block w-5 h-5 ml-2 text-gray-500"
                    data-tooltip-id={`${type}-featureTooltip-${index}`}
                  />
                  <Tooltip id={`${type}-featureTooltip-${index}`}>
                    {feature.description}
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="px-8 py-8 mt-auto">
        <div className="mb-4">
          <label
            htmlFor="player-count"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Gestion de player supplémentaire :
            <div className="text-base text-gray-500 mb-4">
              {additionalPlayerPrice}€ / unité
            </div>
          </label>
          <div className="flex items-center gap-2">
            <button
              onClick={decrementPlayers}
              className="font-bold py-1 px-3 rounded"
              disabled={additionalPlayers <= 0}
            >
              <MinusCircleIcon className="w-6 h-6" />
            </button>
            <span>{additionalPlayers}</span>
            <button
              onClick={incrementPlayers}
              className="font-bold py-1 px-3 rounded"
              disabled={reachedLimit}
            >
              <PlusCircleIcon className="w-6 h-6" />
            </button>
          </div>
          {reachedLimit && (
            <p className="text-red-500 text-sm mt-2">
              Il n'est pas possible d'avoir plus de players en gestion sur ce
              type d'abonnement. Veuillez-nous contacter pour avoir plus
              d'informations sur la formule entreprise.
            </p>
          )}
        </div>
        <button
          className="w-full text-center text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 hover-bg-white"
          onClick={() => onSubscribe(type, additionalPlayers)}
          disabled={reachedLimit}
        >
          Choisir
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
