import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import SettingsModal from "../Modals/SettingsModal";
import BasicManagerModal from "../Modals/BasicManagerModal"; // Import du BasicManagerModal
import { FaEdit } from "react-icons/fa";
import defaultImage from "../../images/device_type_D.png";
import deviceTypeAImage from "../../images/device_type_A.png";
import deviceTypeBImage from "../../images/device_type_B.png";
import deviceTypeCImage from "../../images/device_type_C.png";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const MyPlayersBasic = ({ usedSlots, availableSlots, devices, onDelete }) => {
  const { user } = useUser();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [basicManagerModalOpen, setBasicManagerModalOpen] = useState(false); // State pour BasicManagerModal
  const [currentDevice, setCurrentDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getImageByType = (type) => {
    return (
      { A: deviceTypeAImage, B: deviceTypeBImage, C: deviceTypeCImage }[type] ||
      defaultImage
    );
  };

  const calculateOnlineStatus = (lastOnline) => {
    const lastOnlineDate = new Date(lastOnline);
    const now = new Date();
    const diffInSeconds = (now - lastOnlineDate) / 1000;
    return diffInSeconds <= 60;
  };

  const getOnlineStatusIndicator = (lastOnline) => {
    const isOnline = calculateOnlineStatus(lastOnline);
    const statusColor = isOnline ? "bg-green-500" : "bg-red-500";
    const textStatus = isOnline ? "En ligne" : "Hors ligne";
    return (
      <div className="flex items-center">
        <span className="mr-2">{textStatus}</span>
        <span
          className={`inline-block w-3 h-3 rounded-full ${statusColor}`}
          title={isOnline ? "Online" : "Offline"}
        />
      </div>
    );
  };

  const handleOpenSettingsModal = (device) => {
    setCurrentDevice(device);
    setSettingsModalOpen(true);
  };

  const handleOpenBasicManagerModal = (device) => {
    setCurrentDevice(device);
    setBasicManagerModalOpen(true); // Ouvre BasicManagerModal
  };

  const handleCloseSettingsModal = () => {
    setSettingsModalOpen(false);
  };

  const handleCloseBasicManagerModal = () => {
    setBasicManagerModalOpen(false); // Ferme BasicManagerModal
  };

  const handlePlayerImageClick = (e, device) => {
    e.stopPropagation(); // Empêche l'ouverture de BasicManagerModal
    if (device.type === "B") {
      window.open(`/web-player/${device._id}`, "_blank"); // Ouvre le web player dans un nouvel onglet
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Chargement en cours...</div>
      ) : (
        <div className="space-y-4">
          {" "}
          {/* Remplacement de la grille par flex et full width */}
          {devices.map((device) => (
            <div
              key={device._id}
              className="bg-white rounded-lg shadow-md p-4 mb-4 flex flex-col sm:flex-row sm:items-center justify-between transition-transform transform hover:shadow-lg cursor-pointer w-full" // w-full pour prendre toute la largeur
              onClick={() => handleOpenBasicManagerModal(device)} // Ouvre BasicManagerModal au clic sur la card (sauf l'image)
            >
              <div className="flex items-center">
                <img
                  src={getImageByType(device.type)}
                  alt="Device"
                  className={`h-16 w-16 object-cover mb-2 sm:mb-0 sm:mr-4 transition-transform transform ${
                    device.type === "B" ? "hover:scale-110 cursor-pointer" : ""
                  }`} // Ajout d'un effet de zoom uniquement sur l'image et du curseur pointer
                  onClick={(e) => handlePlayerImageClick(e, device)} // Ouvre WebPlayer uniquement au clic sur l'image
                />
                <h3 className="text-lg font-semibold text-center sm:text-left">
                  {device.name}
                </h3>
              </div>
              <div className="flex justify-between items-center mt-2 sm:mt-0 sm:ml-auto">
                {getOnlineStatusIndicator(device.lastOnline)}
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Empêche d'ouvrir BasicManagerModal
                    handleOpenSettingsModal(device); // Ouvre SettingsModal
                  }}
                  className="text-gray-400 hover:text-gray-500 sm:ml-3"
                >
                  <FaEdit className="text-xl" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Settings Modal */}
      {settingsModalOpen && (
        <SettingsModal
          isOpen={settingsModalOpen}
          onClose={handleCloseSettingsModal}
          device={currentDevice}
          onDelete={onDelete}
        />
      )}

      {/* Basic Manager Modal */}
      {basicManagerModalOpen && (
        <BasicManagerModal
          isOpen={basicManagerModalOpen}
          onClose={handleCloseBasicManagerModal}
          device={currentDevice}
        />
      )}
    </>
  );
};

export default MyPlayersBasic;
